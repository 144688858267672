/*=====================
    Hire Page CSS start
==========================*/

.hire-home {
  &.contact-us-section {
    .contact-content {
      background-color: transparent;
      padding: 0;
      box-shadow: none;
    }
  }
}

.why-outsource {
  &.hire-page {
    background-color: transparent;
    overflow: hidden;

    @media (max-width: 767px) {
      margin-top: 30px;
    }

    .content-section {
      margin-bottom: 0;
    }

    .image-section {
      position: relative;

      .top-effect {
        position: absolute;
      }

      .img1 {
        position: absolute;
        left: 13%;
        width: 46%;
        border-radius: 15px;
        box-shadow: 0 25px 18px rgba(0, 0, 0, 0.07);
      }

      .img2 {
        position: absolute;
        top: 30%;
        left: 45%;
        width: 46%;
        border-radius: 15px;
        box-shadow: 0 25px 18px rgba(0, 0, 0, 0.07);
      }

      .img3 {
        position: absolute;
        bottom: 30px;
        width: 41%;
        left: 22%;
        z-index: -1;
        width: 46%;
        border-radius: 15px;
        box-shadow: 0 25px 18px rgba(0, 0, 0, 0.07);
      }
    }

    .why-us-box {
      margin-left: 30px;
      align-items: center;
      box-shadow: 0px 2px 20px rgba(123, 149, 162, 0.20);

      @media (max-width: 1400px) {
        padding: 22px;
      }

      @media (max-width: 1199px) {
        padding: 30px;
      }

      @media (max-width: 991px) {
        padding: 20px;
      }

      @media (max-width: 380px) {
        margin: 0 5px;
      }

      .whyus-icon {
        background-color: #e5f0fb;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 26px;
        padding: 18px;
        border-radius: 15px;
        margin-left: -50px;
        width: 70px;
        height: 70px;
        border-radius: 28% 70% 70% 30%/30% 30% 70% 70%;

        @media (max-width: 1400px) {
          margin-right: 16px;
        }

        @media (max-width: 1199px) {
          width: 60px;
          height: 60px;
          padding: 14px;
        }

        @media (max-width: 380px) {
          margin: 0 auto 15px;
        }
      }

      .whyus-content {
        width: 82%;

        @media (max-width: 767px) {
          width: 92%;
        }

        @media (max-width: 380px) {
          width: 100%;
        }

        h3 {
          @media (max-width: 1199px) {
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
          }
        }

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

// hiring modal
.model-box {
  padding: 30px 25px;
  border: 1px dashed #ddd;
  border-radius: 20px;
  box-shadow: 0 0 8px #f7f7f7;
  background-color: $white;
  z-index: 1;
  position: relative;

  @media (max-width: 1199px) {
    padding: 20px 15px;
    border-radius: 18px;
  }

  .modal-top {
    text-align: center;
    margin-bottom: 20px;

    @media (max-width: 991px) {
      margin-bottom: 15px;
    }

    .model-title {
      font-size: 24px;
      text-transform: capitalize;
      font-weight: 600;
      margin-bottom: 6px;

      @media (max-width: 1199px) {
        font-size: 18px;
        margin-bottom: 3px;
      }
    }
  }

  .duration-box {
    padding: 20px 25px;
    border-radius: 10px;
    background-color: #eef5fc;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;

    i {
      font-size: 50px;
      position: absolute;
      right: 20px;
      opacity: 0.03;
      bottom: 20px;
    }

    img {
      width: 50px;
      position: absolute;
      right: 20px;
      opacity: 0.1;
      bottom: 20px;

      @media (max-width: 1400px) {
        width: 40px;
        right: 10px;
        bottom: 20px;
      }
    }

    span {
      font-size: 30px;
      color: $black;
      font-weight: 600;
      margin-right: 15px;

      @media (max-width: 1400px) {
        font-size: 24px;
        margin-right: 10px;
      }

      @media (max-width: 1199px) {
        font-size: 20px;
      }
    }

    h4 {
      margin-bottom: 5px;
      color: $title-color;
      font-weight: 600;

      @media (max-width: 1400px) {
        margin-bottom: 3px;
        font-weight: 600;
        font-size: 16px;
      }
    }

    h6 {
      @media (max-width: 1400px) {
        font-size: 14px;
      }
    }

    &.pink-shade {
      background-color: #fbf0e9;
    }

    &.purple-shade {
      background-color: #f8eefc;
    }
  }

  .feature-section {
    padding-left: 15px;
    margin: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320))) 0 0;

    @media (max-width: 1199px) {
      margin: 0;
    }

    @media (max-width: 576px) {
      padding-left: 3px;
    }

    ul {
      padding-inline-start: 40px;

      @media (max-width: 1400px) {
        padding-inline-start: 30px;
      }
    }

    li {
      display: block;
      color: $content-color;
      position: relative;
      font-size: 16px;

      @media (max-width: 1400px) {
        font-size: 14px;
      }

      &:after {
        font-family: "Font Awesome 5 Free";
        content: "\f00c";
        position: absolute;
        font-size: 16px;
        color: $content-color;
        font-weight: 900;
        left: -34px;

        @media (max-width: 1400px) {
          left: -28px;
        }
      }

      +li {
        margin-top: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));

      }
    }
  }

  .btn-theme {
    text-transform: capitalize;
    border-radius: 6px;
  }
}

// comparison table
.comparison-section {
  position: relative;
  z-index: 1;

  /* width */
  ::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $theme-color1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .comparison-table {
    background-color: $white;
    margin-top: 15px;
    font-size: 16px;
    position: relative;
    box-shadow: 0px 2px 20px rgba(123, 149, 162, 0.20);

    table {
      th {
        padding: 20px 20px !important;
        letter-spacing: 0.02em;
        color: $title-color;

        &:nth-child(2) {
          color: $white;
          font-size: 18px;
          padding: 14px !important;
          font-weight: 600;
          background-color: $theme-color1 !important;

          img {
            width: 140px;
          }
        }
      }

      tr {
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }

      td,
      th {
        text-align: center;
        min-width: 220px;
        border-color: #f3f3f3 !important;
        padding: 13px 20px;
        vertical-align: middle;

        @media (max-width: 1199px) {
          font-size: 14px;
        }

        @media (max-width: 767px) {
          min-width: 260px;
        }

        @media (max-width: 576px) {
          min-width: 220px;
        }

        &:first-child {
          text-align: left;
        }

        &:nth-child(2) {
          box-shadow: 0px 10px 15px #e8e8e8;
          border-color: transparent !important;
          background-color: #f0f8ff;
        }
      }
    }
  }
}

// faq section
.faq-section {
  position: relative;

  .faq-container {
    position: relative;
    z-index: 1;

    .row {
      &.g-4 {
        >div {
          @media (max-width: 767px) {
            &:nth-child(2) {
              margin-top: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
            }
          }
        }
      }
    }

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $theme-color1;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .accordion {
      overflow: auto;
      height: 450px;

      @media (max-width: 767px) {
        height: auto;
      }
    }
  }

  .top-effect {
    right: -100px;
    position: absolute;
    bottom: -120px;
    z-index: -1;
    margin-left: auto;
    transform: scale(0.8);
  }

  .accordion {
    padding: 2px;

    .accordion-item {
      position: relative;
      border: none;
      background-color: $white;
      backdrop-filter: blur(6px);
      box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.12);

      .accordion-body {
        padding: 0 1.25rem 1rem 1.25rem;

        p {
          font-size: 14px;
          line-height: 1.7;
          letter-spacing: 0.02em;
        }
      }

      .accordion-button {
        box-shadow: none;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        color: $title-color;
        background-color: $white;
        padding-right: calc(52px + (45 - 52) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(22px + (26 - 22) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;

        @media (max-width: 576px) {
          padding-right: 39px;
        }

        &:after {
          position: absolute;
          right: 20px;

          @media (max-width: 576px) {
            right: 15px;
          }
        }
      }

      +.accordion-item {
        margin-top: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
}

// hire detail
.hire-detail-sec {
  h3.hire-title {
    margin-bottom: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-size: 20px;
    font-weight: 600;
  }

  .hire-detail-row {
    margin-top: 25px;
  }

  .hire-detail {
    margin-bottom: -14px;

    .hire-title {
      font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    p {
      margin-bottom: 14px;
    }
  }

  .hire-box {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 2px 20px rgba(123, 149, 162, 0.20);
    padding: 25px 65px 25px 25px;
    position: relative;
    overflow: hidden;

    h3 {
      margin-bottom: calc(5px + (12 - 5) * ((100vw - 320px) / (1920 - 320)));
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 600;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    img {
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translateY(-50%) rotate(4deg);
      opacity: 0.4;
      width: 60px;
      height: 60px;
      object-fit: cover;
      object-position: left;
      transition: all 0.5s ease;
    }

    &:hover {
      img {
        transform: translateY(-50%) rotate(-4deg);
        transition: all 0.5s ease;
      }
    }
  }
}

.single-tech-section {
  .single-tech-listing {
    .row {
      box-shadow: 0px 2px 20px rgba(123, 149, 162, 0.20);
      padding: 22px;
      background-color: $white;

      @media (max-width: 576px) {
        margin: 0 15px;
      }

      >div {
        border-right: 1px solid $light-content;
        border-bottom: 1px solid $light-content;

        @media (max-width: 420px) {
          width: 50%;
        }

        &:nth-last-child(-n + 4) {
          border-bottom: none;
        }

        &:nth-last-child(4) {
          border-left: 1px solid $light-content;
        }

        &:nth-child(6),
        &:nth-child(12) {
          border-right: none;
        }
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
    }

    .single-box {
      text-align: center;
      background-color: $white;
      border-radius: 8px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px;

      @media (max-width: 991px) {
        padding: 15px;
      }

      h3 {
        font-size: 18px;
        font-weight: 600;
      }

      img {
        margin-bottom: 12px;

        @media (max-width: 767px) {
          margin-bottom: 8px;
        }

        @media (max-width: 576px) {
          width: 30px;
          height: auto;
        }
      }

      h4 {
        color: $content-color;
        font-size: 16px;

        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
}

.hire-note {
  border: 1px dashed #2a87db;
  margin-top: 50px;
  padding: 20px;
  background-color: #eef5fc;
  border-radius: 14px;

  h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .row {
    margin-top: 20px;
  }
}

// effect
.dot-effect {
  position: absolute;
  z-index: -1;

  &.bottom-right {
    right: -40px;
    bottom: -40px;
  }
  
  &.bottom-left {
    left: -40px;
    bottom: -40px;
  }
}