/*=====================
    Home Page CSS start
==========================*/

// service section
.service-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-top: -120px;

    @media (max-width: 991px) {
        margin-top: 30px;
    }

    .service-box {
        text-align: center;
        box-shadow: 0px 2px 20px rgba(123, 149, 162, 0.15);
        border-radius: 20px;
        backdrop-filter: blur(6px);
        background-color: rgba($white, 0.65);
        padding: 40px;
        height: 100%;

        .service-icon {
            width: 70px;
            height: 70px;
            margin: -60px auto 20px;
            background-color: $theme-color1;
            display: flex;
            align-items: center;
            padding: 7px;
            border-radius: 100%;
            background: linear-gradient(27deg, rgba(42, 135, 219, 1) 0%, rgba(16, 91, 203, 1) 100%);
            border-radius: 28% 70% 70% 30%/30% 30% 70% 70%;

            @media (max-width: 1199px) {
                width: 68px;
                height: 68px;
            }

            @media (max-width: 991px) {
                margin: -40px auto 8px;
            }

            @media (max-width: 480px) {
                width: 58px;
                height: 58px;
            }
        }

        svg {
            margin: 0 auto;
            width: 60%;
        }

        img {
            width: 56px;
            margin: 0 auto;
            filter: invert(1);

            @media (max-width: 480px) {
                width: 50px;
            }
        }

        .service-content {
            h3 {
                font-weight: 600;
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            }

            p {
                margin-top: calc(8px + (18 - 8) * ((100vw - 320px) / (1920 - 320)));
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-bottom: -3px;
            }

            .service-list {
                margin-bottom: -5px;

                li {
                    font-size: 16px;
                    margin-bottom: 5px;
                    line-height: 14px;

                    a {
                        color: $title-color;
                        font-size: 14px;
                    }

                    + li {
                        margin-left: 8px;
                        padding-left: 8px;
                        border-left: 1px solid #dddddd;
                    }
                }
            }
        }

        @media (max-width: 1367px) {
            padding: 20px;
        }

        @media (max-width: 1199px) {
            padding: 30px;
        }

        @media (max-width: 991px) {
            padding: 20px;
        }

        @media (max-width: 767px) {
            padding: 30px;
            max-width: 94%;
            margin: 0 auto;
        }

        @media (max-width: 460px) {
            padding: 18px;
        }
    }

    .section-row {
        .row {
            margin-top: -30px;
            margin-bottom: calc(-15px + (-30 - -15) * ((100vw - 320px) / (1920 - 320)));

            > div {
                padding-top: 30px;
                padding-bottom: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }
}

// technology section
.technology-section {
    position: relative;
    z-index: 1;

    .nav-tabs {
        justify-content: center;

        .nav-link {
            color: $content-color;
            font-size: 18px;
            padding: 0 25px 8px;
            border: none;
            text-transform: capitalize;

            &.active {
                background-color: transparent;
                color: $theme-color1;
                border-bottom: 1px solid $theme-color1;
            }
        }
    }

    .tab-content {
        display: block;
        width: 80%;
        margin: 50px auto 0;

        .tab-pane {
            &.active,
            &.show {
                .tech-box {
                    animation: zoomIn 300ms ease-in-out;
                }
            }
        }
    }

    .tech-wrap {
        background-color: $white;
        padding: 25px;
        border-radius: 15px;
        height: 100%;
        min-height: 250px;
        box-shadow: rgba(22, 31, 39, 0.05) 0px 60px 123px -25px, rgba(19, 26, 32, 0.06) 0px 35px 75px -35px;
        position: relative;

        @media (max-width: 767px) {
            padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        }

        @media (max-width: 480px) {
            min-height: unset;
        }

        .wrap-title {
            display: flex;
            align-items: center;
            text-transform: capitalize;
            margin-bottom: 20px;
            border-bottom: 1px dashed #ddd;
            padding-bottom: 15px;

            h3 {
                font-weight: 600;
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            }

            img {
                width: calc(26px + (34 - 26) * ((100vw - 320px) / (1920 - 320)));
                margin-right: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
            }
        }

        .content {
            ul {
                margin-bottom: -15px;

                @media (max-width: 480px) {
                    column-count: 2;
                    margin-bottom: 0;
                }

                li {
                    display: block;
                    color: $content-color;
                    line-height: 1.7;
                    margin-bottom: 15px;

                    img {
                        width: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
                        margin-right: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
                    }

                    a {
                        color: $content-color;
                        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                    }
                }

                &.column-2 {
                    column-count: 2;
                }
            }
        }
    }
}

// why outsource
.why-outsource {
    position: relative;

    .why-us-box {
        padding: 30px;
        background-color: $white;
        border-radius: 20px;
        display: flex;
        height: 100%;
        box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.06);

        @media (max-width: 1199px) {
            padding: 20px;
        }

        @media (max-width: 991px) {
            padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
        }

        @media (max-width: 380px) {
            display: block;
            text-align: center;
        }

        .whyus-content {
            width: 80%;

            h3 {
                margin-bottom: 12px;
                font-size: 18px;
                font-weight: 600;
                text-transform: capitalize;

                @media (max-width: 991px) {
                    margin-bottom: 6px;
                    font-size: 16px;
                }
            }

            p {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            @media (max-width: 767px) {
                width: 85%;
            }

            @media (max-width: 380px) {
                width: 100%;
            }
        }

        .whyus-icon {
            position: relative;
            background-color: $white;
            display: inline-block;
            width: 70px;
            height: 70px;
            padding-right: 25px;

            @media (max-width: 991px) {
                width: 60px;
                height: 60px;
                padding-right: 20px;
            }

            @media (max-width: 767px) {
                width: 15%;
            }

            @media (max-width: 380px) {
                padding-right: 0;
            }
        }
    }

    .slick-list {
        margin: 0 -15px;

        .slick-slide {
            > div {
                margin: 0 15px;
            }
        }
    }
}

// development process
.development-section {
    .tab-slider--nav {
        width: 100%;
        float: left;
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        margin-top: 15px;

        .tab-slider--tabs {
            display: block;
            float: left;
            margin: 0;
            padding: 0;
            list-style: none;
            position: relative;
            border-radius: 35px;
            overflow: hidden;
            background: rgba(42, 135, 219, 0.1);
            user-select: none;

            @media (max-width: 460px) {
                border-radius: 0;
                background: none;
                display: flex;
                margin: 0 -5px;
                overflow: auto;
            }

            &:after {
                content: "";
                width: 50%;
                background: $theme-color1;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transition: all 250ms ease-in-out;
                border-radius: 35px;

                @media (max-width: 460px) {
                    display: none;
                }
            }

            &.slide:after {
                left: 50%;
            }

            .tab-slider--trigger {
                font-size: 14px;
                line-height: 1;
                font-weight: bold;
                color: $theme-color1;
                text-transform: uppercase;
                text-align: center;
                padding: 12px 20px;
                position: relative;
                z-index: 2;
                cursor: pointer;
                display: inline-block;
                transition: color 250ms ease-in-out;
                user-select: none;

                @media (max-width: 460px) {
                    border-radius: 35px;
                    background: rgba(42, 135, 219, 0.1);
                    white-space: nowrap;
                    margin: 0 5px;
                }

                &.active {
                    color: $white;

                    @media (max-width: 460px) {
                        background-color: $theme-color1;
                    }
                }
            }
        }
    }

    .development-box {
        background-color: $white;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        padding: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)))
            calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
        transition: all 0.5s ease;
        position: relative;
        overflow: hidden;
        z-index: 1;

        .number-effect {
            content: "";
            position: absolute;
            font-size: calc(45px + (70 - 45) * ((100vw - 320px) / (1920 - 320)));
            right: 10px;
            bottom: calc(-24px + (-30 - -24) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 900;
            color: #e9f3fb;
        }

        .development-icon {
            h1 {
                color: $theme-color1;
                font-size: 30px;
                font-weight: 600;
            }

            img {
                width: auto;
            }
        }

        .development-content {
            margin-top: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));

            h3 {
                text-transform: capitalize;
                margin-bottom: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 600;
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            }

            p {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-bottom: -3px;
                width: 86%;
            }
        }

        &:hover {
            transform: translateY(-10px);
            transition: all 0.5s ease;
        }
    }
}

// testimonial section
.testimonial-section {
    position: relative;

    .testimonial-row {
        position: relative;

        &::after {
            opacity: 0;
            content: "";
            position: absolute;
            left: -30px;
            height: 100%;
            top: 4px;
            width: 100px;
            background: linear-gradient(
                to left,
                rgba(0, 0, 0, 0) 0%,
                rgba(255, 255, 255, 0) 4%,
                rgba(251, 243, 237, 1) 97%,
                rgba(251, 243, 237, 1) 100%
            );
            z-index: 1;
        }

        &::before {
            opacity: 0;
            content: "";
            position: absolute;
            right: -30px;
            height: 100%;
            top: 4px;
            width: 100px;
            background: linear-gradient(
                to right,
                rgba(0, 0, 0, 0) 0%,
                rgba(255, 255, 255, 0) 4%,
                rgba(251, 243, 237, 1) 97%,
                rgba(251, 243, 237, 1) 100%
            );
            z-index: 1;
        }
    }

    .slick-list {
        margin: 0 calc(-7px + (-15 - -7) * ((100vw - 320px) / (1920 - 320)));

        .slick-slide {
            > div {
                margin: 0 calc(7px + (15 - 7) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }

    .slick-dots {
        position: relative;

        li {
            button {
                &:before {
                    font-size: 12px;
                    color: $theme-color1;
                }
            }
        }
    }

    .testimonial-box {
        padding: 10px;
        border-radius: 20px;
        background-color: $white;
        display: block;
        box-shadow: rgba(22, 31, 39, 0.05) 0px 0 123px -25px, rgba(19, 26, 32, 0.06) 0 0 75px -35px;

        .testimonial-img {
            border-radius: 16px;
            position: relative;
        }

        .testimonial-content {
            h3 {
                color: $title-color;
                margin-top: 15px;
            }

            h5 {
                font-weight: 400;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-height: 1.5;
            }
        }
    }

    .video-play-button {
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0.5);
        box-sizing: content-box;
        display: block;
        width: 32px;
        height: 44px;
        border-radius: 50%;
        padding: 18px 20px 18px 28px;

        span {
            display: block;
            position: relative;
            z-index: 3;
            width: 0;
            height: 0;
            border-left: 32px solid $theme-color1;
            border-top: 22px solid transparent;
            border-bottom: 22px solid transparent;
        }

        &:before {
            content: "";
            position: absolute;
            z-index: 0;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            display: block;
            width: 80px;
            height: 80px;
            background-color: $white;
            border-radius: 50%;
            animation: pulse-border 1500ms ease-out infinite;
        }

        &:after {
            content: "";
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            display: block;
            width: 80px;
            height: 80px;
            background: $white;
            border-radius: 50%;
            transition: all 200ms;
        }
    }

    @keyframes pulse-border {
        0% {
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
            opacity: 1;
        }

        100% {
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
            opacity: 0;
        }
    }
}

@keyframes up-down {
    0% {
        transform: translateY(22px);
    }

    50% {
        transform: translateY(12px);
    }

    100% {
        transform: translateY(22px);
    }
}

// contact us section
.contact-us-section {
    .title {
        h2 {
            font-size: calc(22px + (40 - 22) * ((100vw - 320px) / (1920 - 320)));
        }

        span {
            font-size: calc(22px + (40 - 22) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .top-effect {
        .dot-effect {
            position: absolute;
            left: -60px;
            top: 0;
            z-index: -1;
        }

        .bg-effect {
            position: absolute;
            left: -30%;
            top: -15%;
            z-index: -1;
            width: 70%;
            transform: rotate(45deg);
        }
    }

    .contact-details {
        @media (max-width: 991px) {
            margin-bottom: 50px;
        }

        img {
            width: 80%;
        }

        .contact-list {
            li {
                display: block;
                margin-bottom: 10px;
                font-size: 18px;
                line-height: 1.8;

                svg {
                    margin-right: 8px;
                }
            }
        }

        .social-list {
            margin-top: 20px;

            ul {
                display: flex;
                margin: 0 -8px;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 60px;
                    border-radius: 100%;
                    margin: 0 8px;

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    i {
                        font-size: 20px;
                        color: $white;
                    }

                    &.facebook {
                        background-color: #4867aa;
                    }

                    &.twitter {
                        background-color: #5da9dd;
                    }

                    &.instagram {
                        background-color: #c18af7;
                    }
                }
            }
        }
    }

    .nav-tabs {
        flex-wrap: unset;

        .nav-link {
            border: none;
            color: $content-color;
            font-size: 16px;
            padding: 10px 35px;
            border-radius: 10px 10px 0 0;

            @media (max-width: 420px) {
                font-size: 14px;
                padding: 10px 22px;
            }

            &.active {
                background-color: #2780d4;
                color: $white;
                border: none;
                background-size: 300% 100%;
                background-image: linear-gradient(to right, #0c4393, #2780d4, #2780d4, #0c4393);

                i {
                    color: $white;
                }
            }
        }
    }

    .contact-box {
        width: 100%;
        margin: 0 auto calc(-200px + (-280 - -200) * ((100vw - 320px) / (1920 - 320)));
        padding: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));
        box-shadow: 0 0 50px rgba(23, 32, 90, 0.06);
        background-color: $white;
        position: relative;
        z-index: 1;
        border-radius: 20px;

        .contact-details {
            ul {
                margin: 0 0 calc(-20px + (-30 - -20) * ((100vw - 320px) / (1920 - 320)));

                li {
                    display: flex;
                    align-items: center;
                    margin: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320))) 0;

                    @media (max-width: 576px) {
                        align-items: flex-start;
                    }

                    .contact-icon {
                        width: calc(48px + (58 - 48) * ((100vw - 320px) / (1920 - 320)));
                        height: calc(48px + (58 - 48) * ((100vw - 320px) / (1920 - 320)));
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 20px;
                        background: linear-gradient(27deg, #2a87db 0%, #105bcb 100%);
                        border-radius: 28% 70% 70% 30%/30% 30% 70% 70%;

                        svg {
                            stroke: white;
                        }
                    }

                    .contact-content {
                        width: 70%;

                        h5 {
                            font-weight: 600;
                            margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
                            color: $title-color;
                            font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
                        }

                        h6 {
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                        }
                    }
                }
            }
        }
    }

    &.contact-page {
        .title {
            margin-top: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));

            .title-effect {
                font-size: calc(42px + (75 - 42) * ((100vw - 320px) / (1920 - 320)));
            }

            h2 {
                font-size: calc(22px + (48 - 22) * ((100vw - 320px) / (1920 - 320)));
            }

            span {
                font-size: calc(22px + (48 - 22) * ((100vw - 320px) / (1920 - 320)));
            }

            p {
                width: 80%;
                margin-bottom: calc(0px + (40 - 0) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }
}

footer {
    position: relative;
    padding-top: calc(180px + (250 - 180) * ((100vw - 320px) / (1920 - 320))) !important;
    background-image: url(../images/background/footer.png);
    background-repeat: no-repeat;
    background-size: cover;

    .footer-logo {
        h3 {
            font-weight: 600;
            margin-bottom: calc(12px + (22 - 12) * ((100vw - 320px) / (1920 - 320)));
            font-size: 34px;
            color: $theme-color1;
        }

        img {
            width: calc(120px + (160 - 120) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: calc(12px + (22 - 12) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .sub-title {
        width: 100%;
    }

    .footer-title {
        margin-bottom: 38px;
        position: relative;
        padding-bottom: 4px;

        @media (max-width: 767px) {
            margin-bottom: 0;
            padding-bottom: 0;

            &:after {
                content: "";
                position: absolute;
                border-bottom: 1px solid #dddddd;
            }
        }

        h4 {
            color: $light-title;
            text-transform: capitalize;
            font-weight: 600;

            @media (max-width: 767px) {
                font-size: 16px;
            }
        }

        &:after {
            content: "";
            position: absolute;
            width: 38px;
            height: 2px;
            background-color: #4c9be1;
            bottom: -16px;

            @media (max-width: 767px) {
                display: none;
            }
        }

        &.active {
            .according-menu {
                &::before {
                    content: "\f077";
                }
            }
        }

        .according-menu {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;

            &::before {
                content: "\f078";
                position: absolute;
                right: 2px;
                top: 3px;
                font-size: 12px;
                width: 24px;
                height: 24px;
                background-color: rgba($theme-color1, 0.16);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                color: $theme-color1;
            }
        }
    }

    .sub-footer {
        overflow: hidden;

        @media (max-width: 767px) {
            text-align: center;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            color: $light-title;

            span {
                color: $light-title;
                font-weight: 600;
            }
        }
    }

    .subfooter-content {
        text-align: right;
        margin-left: 8px;
        margin-right: 8px;

        @media (max-width: 767px) {
            text-align: center;
            margin-top: 10px;
        }

        li {
            margin-left: 0;
            padding-left: 8px;
            padding-right: 8px;
            line-height: 12px;

            a {
                color: $light-title;
                text-transform: capitalize;
                font-size: 13px;
                font-weight: 500;
            }

            + li {
                margin-left: 15px;
                padding-left: 15px;
                border-left: 1px solid #3e8cdf;

                @media (max-width: 767px) {
                    margin-left: 0;
                    padding-left: 8px;
                    padding-right: 8px;
                }
            }
        }
    }

    .footer-contant {
        @media (max-width: 767px) {
            padding-top: 20px;
        }

        p {
            font-size: 14px;
            width: 85%;
            line-height: 1.7;
            color: $light-title;

            @media (max-width: 767px) {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                width: 100%;
            }
        }

        li {
            display: block;
            letter-spacing: 0.02em;
            color: $light-title;
            font-size: 14px;
            font-weight: 500;

            i {
                margin-right: 12px;
                color: $light-title;
            }

            a {
                color: $light-title;
                font-weight: 500;
            }

            + li {
                margin-top: 15px;
            }
        }
    }

    .sub-footer {
        .container {
            border-top: 1px solid #3e8cdf;
            padding: 26px 0;
            margin-top: calc(20px + (70 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .row {
        .col {
            display: flex;
            justify-content: center;

            @media (max-width: 991px) {
                flex: 0 0 auto;
                width: 50%;
                justify-content: start;
            }

            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }

    &.no-bg {
        padding-top: calc(40px + (90 - 40) * ((100vw - 320px) / (1920 - 320))) !important;
        background-image: linear-gradient(to right, #0c4393, #2780d4);
    }
}

.effect-right {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    &.effect-sm {
        transform: scale(0.6);
        transform-origin: right top;
    }
}

.effect-left {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    img {
        transform: rotate(180deg);
    }
}

// effect
.shape1 {
    position: absolute;
    left: 5%;
    top: 2%;
    z-index: 1;
}

.shape2 {
    position: absolute;
    left: 4%;
    top: 1%;
}

.shape3 {
    position: absolute;
    right: 5%;
    top: 60px;
    z-index: 1;
    transform: rotate(75deg);
}

.shape4 {
    position: absolute;
    right: 4%;
    top: 40px;
    transform: rotate(75deg);
}
