/*=====================
    Contact Us CSS start
==========================*/

.contact-top-details {
    margin-top: -98px;
    position: relative;
    z-index: 1;
    .contact-box {
        background-color: $white;
        border-radius: 22px;
        text-align: center;
        padding: 36px 20px;
        box-shadow: rgba(22, 31, 39, 0.05) 0px 60px 123px -25px, rgba(19, 26, 32, 0.06) 0px 35px 75px -35px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
            margin-bottom: -4px;
        }
        @media (max-width: 991px) {
            padding: 26px 10px;
        }
        i {
            font-size: 50px;
            color: $theme-color1;
            margin-bottom: 25px;
            @media (max-width: 991px) {
                font-size: 40px;
                margin-bottom: 16px;
            }
        }
        h3 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 8px;
        }
    }
    &.career-page {
        .contact-box {
            padding: calc(22px + (46 - 22) * ((100vw - 320px) / (1920 - 320))) calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
            h3 {
                font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                margin-bottom: calc(4px + (12 - 4) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }
    &.about-page {
        img {
            border-radius: 10px;
            overflow: hidden;
        }
    }
}

.contact-vector {
    position: relative;
    .notification-svg {
        position: absolute;
        right: 24%;
        top: -4%;
        animation: ring 4s .7s ease-in-out infinite;
        @media (max-width: 1400px) {
            right: 13%;
        }
    }
    .message-svg {
        position: absolute;
        right: 42%;
        top: 9%;
        animation: msg 4s ease-in-out infinite;
        @media (max-width: 1400px) {
            right: 36%;
        }
    }
    .tab-svg {
        position: absolute;
        left: 7%;
        animation: tab 4s ease-in-out infinite;
        @media (max-width: 1199px) {
            left: 6%;
            width: 22%;
            top: 6%;
        }
    }
}

