/*=====================
    Reset CSS start
==========================*/
section,
.section-t-space {
  padding-top: calc(40px + 50 * (100vw - 320px) / 1600);
}

.section-b-space {
  padding-bottom: calc(40px + 50 * (100vw - 320px) / 1600);
}

.medium-t-space {
  padding-top: calc(25px + 45 * (100vw - 320px) / 1600);
}

.medium-b-space {
  padding-bottom: calc(25px + 45 * (100vw - 320px) / 1600);
}

.small-t-space {
  padding-top: calc(20px + 30 * (100vw - 320px) / 1600);
}

.small-b-space {
  padding-bottom: calc(20px + 30 * (100vw - 320px) / 1600);
}

.custom-padding {
  padding: 0 100px;
}

.sticky-top {
  position: sticky;
  top: 110px;
  z-index: 1;
}

.alert-theme {
  background: linear-gradient(27deg, #f0f8ff 0%, #deeafb 100%);
  border: none;
  margin-bottom: 0;
}
.alert-theme strong {
  font-weight: 600;
}
.alert-theme .btn-close {
  background-size: 12px;
}

@media (min-width: 1430px) {
  .container {
    max-width: 1400px;
  }
}
/*=====================
    Typography CSS start
==========================*/
body {
  font-family: "Montserrat", sans-serif;
  position: relative;
  font-size: 14px;
  margin: 0;
  background-color: #ffffff;
  color: #222222;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

li {
  display: inline-block;
  font-size: 14px;
}

a {
  transition: 0.5s ease;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  transition: 0.5s ease;
}
a:focus {
  outline: none;
}

:focus {
  outline: none;
}

.form-control {
  font-size: 14px;
}
.form-control:focus {
  box-shadow: none;
}

h1 {
  font-size: 58px;
  font-weight: 500;
  line-height: 1.1;
  margin: 0;
  text-transform: capitalize;
  color: #222222;
}
h1 span {
  font-size: 58px;
}

h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  margin: 0;
  color: #222222;
}

h3 {
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  color: #222222;
}

h4 {
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
  color: #222222;
}

h5 {
  font-size: 17px;
  line-height: 1.3;
  margin: 0;
  color: #777777;
}

h6 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
  color: #777777;
}

span {
  font-size: 14px;
}

p {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  line-height: 1.6;
  color: #777777;
  margin-bottom: 0;
}

.title-color {
  color: #222222;
}

.content-color {
  color: #777777;
}

/*=====================
    Animation CSS start
==========================*/
.rotating-text {
  font-weight: 600;
  font-size: 36px;
  color: #ffffff;
  position: relative;
}
.rotating-text h1 {
  display: inline-flex;
  margin: 0;
  vertical-align: top;
}
.rotating-text h1 .word {
  position: absolute;
  display: flex;
  opacity: 0;
}
.rotating-text h1 .word span {
  text-transform: capitalize;
}
.rotating-text h1 .word .letter {
  transform-origin: center center 25px;
}
.rotating-text h1 .word .letter.out {
  transform: rotateX(90deg);
  transition: 0.32s cubic-bezier(0.6, 0, 0.7, 0.2);
}
.rotating-text h1 .word .letter.in {
  transition: 0.38s ease;
}
.rotating-text h1 .word .letter.behind {
  transform: rotateX(-90deg);
}
.rotating-text::after {
  content: "<h1>";
  font-family: "Allura", cursive;
  position: absolute;
  font-size: 22px;
  left: 0;
  top: -30px;
  opacity: 0.4;
}
.rotating-text::before {
  content: "</h1>";
  font-family: "Allura", cursive;
  position: absolute;
  font-size: 22px;
  right: 60px;
  bottom: -40px;
  opacity: 0.4;
}

.cubes .cube {
  position: absolute;
  height: 100px;
  width: 100px;
  margin: 0;
  animation: cube-fade-in 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transform;
  z-index: -1;
  bottom: 50px;
}
@keyframes cube-fade-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
}
.cubes .cube * {
  position: absolute;
  height: 100%;
  width: 100%;
}
.cubes .cube .shadow {
  background: #07427a;
  top: 40%;
}
.cubes .cube .sides {
  transform-style: preserve-3d;
  perspective: 600px;
}
.cubes .cube .sides div {
  backface-visibility: hidden;
  will-change: transform;
}
.cubes .cube .sides .front {
  transform: rotateY(0deg) translateZ(50px);
}
.cubes .cube .sides .back {
  transform: rotateY(-180deg) translateZ(50px);
}
.cubes .cube .sides .left {
  transform: rotateY(-90deg) translateZ(50px);
}
.cubes .cube .sides .right {
  transform: rotateY(90deg) translateZ(50px);
}
.cubes .cube .sides .top {
  transform: rotateX(90deg) translateZ(50px);
}
.cubes .cube .sides .bottom {
  transform: rotateX(-90deg) translateZ(50px);
}

.shapes-container img {
  max-width: 100%;
  height: auto;
  position: absolute;
}
.shapes-container img.tech-bg {
  position: absolute;
  left: 50%;
  z-index: -1;
  transform: translateX(-50%);
  filter: invert(0.1);
}
.shapes-container img.dots1 {
  right: 3%;
  z-index: -1;
  width: 11%;
  filter: invert(0.22);
  bottom: 20px;
}
.shapes-container img.dots2 {
  left: 3%;
  z-index: -1;
  width: 10%;
  filter: invert(0.22);
  top: 9%;
}

@keyframes shimmy {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(8px, -10px);
  }
}
@keyframes shimmy1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(15px);
  }
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes tab {
  0% {
    transform: scale(1) translateX(0px);
  }
  50% {
    transform: scale(0.9) translateX(-6px);
  }
  100% {
    transform: scale(1) translateX(0px);
  }
}
@keyframes msg {
  0% {
    transform: scale(1) translateX(0px);
  }
  50% {
    transform: scale(1.1) translateX(-10px);
  }
  100% {
    transform: scale(1) translateX(0px);
  }
}
/*=====================
    Button CSS start
==========================*/
button:focus {
  outline: none;
}

.btn-theme {
  min-width: 180px;
  padding: calc(12px + 2 * (100vw - 320px) / 1600) calc(20px + 10 * (100vw - 320px) / 1600);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  background-image: linear-gradient(to right, #0c4393, #2780d4, #2780d4, #0c4393);
  box-shadow: 0 5px 15px rgba(39, 128, 212, 0.4);
  transition: all 0.4s ease-in-out;
  text-transform: uppercase;
  border-radius: 10px;
}
.btn-theme:hover {
  background-position: 100% 0;
  transition: all 0.4s ease-in-out;
  color: #ffffff;
}
.btn-theme.btn-sm {
  text-transform: capitalize;
  padding: 10px 24px;
  width: auto;
}
.btn-theme.btn-white {
  background-image: none;
  color: #2780d4;
  background-color: #ffffff;
  box-shadow: rgba(39, 128, 212, 0.1) 2px 1px 10px;
}
.btn-theme.btn-light {
  background-color: #4c9be1;
  background-image: none;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
}

/*=====================
    Title CSS start
==========================*/
.title {
  text-align: center;
  position: relative;
  z-index: 1;
  display: inline-block;
}
.title h2 {
  font-size: calc(22px + 26 * (100vw - 320px) / 1600);
  position: relative;
  display: inline-block;
  text-transform: initial;
}
.title h2 span {
  font-size: calc(22px + 26 * (100vw - 320px) / 1600);
  color: #2780d4;
}
.title p {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  margin-top: calc(6px + 6 * (100vw - 320px) / 1600);
  color: #777777;
}
@media (max-width: 1199px) {
  .title p {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .title p {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .title p {
    width: 100%;
  }
}
.title img {
  position: absolute;
  width: 40px;
  left: -9px;
  z-index: -1;
  top: 10px;
  transform: rotate(75deg);
  opacity: 0;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes run {
  0% {
    left: 49.2%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 49.2%;
  }
}
.title h5 {
  color: #777777;
  margin-top: -3px;
  font-weight: 400;
  margin-bottom: calc(3px + 5 * (100vw - 320px) / 1600);
  font-size: calc(15px + 1 * (100vw - 320px) / 1600);
}
.title .title-effect {
  width: calc(320px + 540 * (100vw - 320px) / 1600);
  background: linear-gradient(180deg, rgb(221, 239, 255) 0%, rgb(255, 255, 255) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  -webkit-background-clip: text;
  font-size: calc(42px + 33 * (100vw - 320px) / 1600);
  font-weight: 700;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(-35px + -15 * (100vw - 320px) / 1600);
  background: linear-gradient(180deg, #ddefff 0%, #fff 100%);
  background-clip: text;
  display: inline-block;
  -webkit-background-clip: text;
  font-size: calc(27px + 33 * (100vw - 320px) / 1600);
  font-weight: 700;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: calc(-35px + -15 * (100vw - 320px) / 1600);
}
.title.text-start .title-effect {
  left: 0;
  transform: none;
}

/*=====================
    form CSS start
==========================*/
.form-control {
  border: 1px solid #eeeeee;
  border-radius: 8px;
}

.floating-height {
  height: 46px;
  line-height: 32px;
}

.file-input {
  display: inline-block;
  text-align: left;
  background: #ffffff;
  padding: 0;
  position: relative;
  border-radius: 3px;
  width: 100%;
  padding: 10px;
  border: 1px dashed #ddd;
  border-radius: 8px;
  background-color: #ffffff;
}
@media (max-width: 380px) {
  .file-input {
    padding: 10px;
  }
}
.file-input > [type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}
.file-input > .button {
  display: inline-block;
  cursor: pointer;
  background: #eee;
  padding: 8px 16px;
  border-radius: 2px;
  margin-right: 8px;
}
@media (max-width: 380px) {
  .file-input > .button {
    margin-right: 2px;
    padding: 6px 10px;
  }
}
.file-input > .label {
  color: #777777;
  white-space: nowrap;
  opacity: 1;
}
.file-input .-chosen > .label {
  opacity: 1;
}

/*=====================
    Ratio CSS start
==========================*/
.ratio_40 .bg-size:before {
  padding-top: 40%;
  content: "";
  display: block;
}

.ratio_45 .bg-size:before {
  padding-top: 45%;
  content: "";
  display: block;
}

.ratio2_1 .bg-size:before {
  padding-top: 50%;
  content: "";
  display: block;
}

.ratio_55 .bg-size:before {
  padding-top: 55%;
  content: "";
  display: block;
}

.ratio_59 .bg-size:before {
  padding-top: 59%;
  content: "";
  display: block;
}

.ratio2_3 .bg-size:before {
  padding-top: 60%;
  content: "";
  display: block;
}

.ratio3_2 .bg-size:before {
  padding-top: 66.66%;
  content: "";
  display: block;
}

.ratio_90 .bg-size:before {
  padding-top: 93%;
  content: "";
  display: block;
}

.ratio_landscape .bg-size:before {
  padding-top: 75%;
  content: "";
  display: block;
}

.ratio_square .bg-size:before {
  padding-top: 100%;
  content: "";
  display: block;
}

.ratio_asos .bg-size:before {
  padding-top: 127.7777778%;
  content: "";
  display: block;
}

.ratio_portrait .bg-size:before {
  padding-top: 150%;
  content: "";
  display: block;
}

.ratio1_2 .bg-size:before {
  padding-top: 200%;
  content: "";
  display: block;
}

.b-top {
  background-position: top !important;
}

.b-bottom {
  background-position: bottom !important;
}

.b-center {
  background-position: center !important;
}

.b-left {
  background-position: left !important;
}

.b-right {
  background-position: right !important;
}

.b_size_content {
  background-size: contain !important;
}

/*=====================
   1.General CSS start
=====================*/
.dropdown-header {
  display: block;
  padding: 0.5rem 1.3rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #4a4b65;
  white-space: nowrap;
}

.navbar-nav {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-item {
  margin-bottom: 0;
  border-top: 1px solid #e9e9f2;
}
.navbar-nav .dropdown-menu {
  min-width: 12.5rem;
  margin: 0;
  margin-top: -0.5rem;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  position: static;
}
.navbar-nav .dropdown-menu .dropdown {
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  border-left: 1px solid #e9e9f2;
}
.navbar-nav .dropdown-menu .dropdown:last-child {
  margin-bottom: 0.5rem;
}
.navbar-nav .dropdown-menu .dropdown .dropdown-toggle::after {
  display: none;
}
.navbar-nav .dropdown-menu .dropdown-menu {
  display: block;
  padding-right: 1rem;
  padding-left: 1rem;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  font-size: calc(1.2875rem + 0.45vw);
  white-space: nowrap;
  display: inline-block;
  font-weight: 500;
}
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-brand:focus {
  text-decoration: none;
}

.navbar.navbar-expand-xl .navbar-nav .nav-link {
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 1199px) {
  .navbar.navbar-expand-xl .navbar-nav .nav-link {
    padding: 0;
  }
}

.navbar-text {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  transition: box-shadow 0.15s ease-in-out;
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #4a4b65;
}
.navbar-light .navbar-brand:hover {
  color: #4a4b65;
}
.navbar-light .navbar-brand:focus {
  color: #4a4b65;
}
.navbar-light .navbar-nav .nav-link {
  color: #5a5b75;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #2780d4;
}
.navbar-light .navbar-nav .nav-link:focus {
  color: #2780d4;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: #9e9fb4;
}
.navbar-light .navbar-nav .show > .nav-link {
  color: #2780d4;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #2780d4;
}
.navbar-light .navbar-toggler {
  color: #5a5b75;
  border-color: transparent;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml, %3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%235a5b75' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: #5a5b75;
}
.navbar-light .navbar-text a {
  color: #2780d4;
}
.navbar-light .navbar-text a:hover {
  color: #2780d4;
}
.navbar-light .navbar-text a:focus {
  color: #2780d4;
}

.navbar {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.navbar:not(.navbar-stuck) .navbar-stuck-btn {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.navbar-shadow {
  box-shadow: 0 0.125rem 0.625rem -0.1875rem rgba(0, 0, 0, 0.1);
}

.navbar-floating {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.navbar-stuck .navbar-btn {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.navbar-stuck-logo {
  display: none;
}

.navbar-sticky.navbar-stuck {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  animation: navbar-show 0.25s;
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.625rem -0.1875rem rgba(0, 0, 0, 0.1);
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1080;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #ffffff;
  background-clip: padding-box;
  outline: 0;
  box-shadow: -0.0625rem 0 0.625rem 0 rgba(0, 0, 0, 0.07), 0.3125rem 1.25rem 2.5rem 0 rgba(0, 0, 0, 0.04);
  transition: transform 0.3s ease-in-out;
  will-change: transform, box-shadow;
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), box-shadow 0.3s ease;
  box-shadow: none;
  visibility: visible !important;
}
.offcanvas:not(.offcanvas-end):not(.offcanvas-bottom) {
  top: 0;
  left: 0;
  width: 22rem;
  border-right: 0 solid transparent;
  transform: translateX(-100%);
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 1.25rem 1.5rem;
}
.offcanvas-header .btn-close {
  padding: 0.625rem 0.75rem;
  margin-top: -0.625rem;
  margin-right: -0.75rem;
  margin-bottom: -0.625rem;
  font-size: 16px;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: initial;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1.25rem 1.5rem;
  overflow-y: auto;
  height: 100%;
}
.offcanvas-body::-webkit-scrollbar {
  width: 0.5rem;
  background-color: transparent;
  opacity: 0;
}
.offcanvas-body::-webkit-scrollbar-thumb {
  background-color: #dfdfeb;
  border-radius: 0.25rem;
}
.offcanvas-body > .simplebar-track {
  display: block;
  background-color: transparent;
}
.offcanvas-body .simplebar-vertical {
  margin-right: 0.1875rem;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 22rem;
  border-right: 0 solid transparent;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 22rem;
  border-left: 0 solid transparent;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 0 solid transparent;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 0 solid transparent;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
  transform: none !important;
  box-shadow: -0.0625rem 0 0.625rem 0 rgba(0, 0, 0, 0.07), 0.3125rem 1.25rem 2.5rem 0 rgba(0, 0, 0, 0.04) !important;
}

.offcanvas-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 1.25rem 1.5rem;
}

.sidebar-toggle {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  z-index: 1025;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    -webkit-transition: none;
    transition: none;
  }
  .offcanvas {
    -webkit-transition: none;
    transition: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl.navbar .offcanvas-body {
    overflow: visible;
  }
  .navbar-expand-xl.navbar-sticky.navbar-stuck .navbar-floating-logo {
    display: none !important;
  }
  .navbar-expand-xl.navbar-sticky.navbar-stuck .navbar-stuck-logo {
    display: block;
  }
  .navbar-expand-xl .navbar-nav {
    position: relative;
    padding-top: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-item {
    border: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-item:hover > .nav-link:not(.disabled) {
    color: #2780d4;
  }
  .navbar-expand-xl .navbar-nav .nav-item.active > .nav-link:not(.disabled) {
    color: #2780d4;
  }
  .navbar-expand-xl .navbar-nav > .dropdown > .dropdown-toggle::after {
    display: none;
  }
  .navbar-expand-xl .dropdown-menu {
    margin-top: 0;
    box-shadow: -0.0625rem 0 0.625rem 0 rgba(0, 0, 0, 0.07), 0.3125rem 1.25rem 2.5rem 0 rgba(0, 0, 0, 0.04);
    border-color: #e9e9f2;
    background-color: #ffffff;
    border-color: #ffffff;
    padding: 0;
  }
  .navbar-expand-xl .dropdown-menu li {
    display: block;
    width: 100%;
  }
  .navbar-expand-xl .dropdown-menu .dropdown-column {
    margin: 5px 5px 5px 5px;
    position: relative;
  }
  .navbar-expand-xl .dropdown-menu .dropdown-item {
    padding: 5px 20px 5px 20px;
    margin: 3px 0px 3px 0;
    font-size: 14px;
  }
  .navbar-expand-xl .dropdown-menu .dropdown-item:hover {
    background-color: transparent;
    color: #2780d4;
  }
  .navbar-expand-xl .dropdown-menu .dropdown {
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    border-left: 0;
  }
  .navbar-expand-xl .dropdown-menu .dropdown .dropdown-toggle {
    position: relative;
    padding-right: 1.625rem;
  }
  .navbar-expand-xl .dropdown-menu .dropdown .dropdown-toggle::after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    width: 7px;
    height: 7px;
    border-top: 2px solid #555555;
    border-left: 2px solid #555555;
    border-right: none;
    border-bottom: none;
    transform: translate(-50%, -50%) rotate(135deg);
  }
  .navbar-expand-xl .dropdown-menu .dropdown:last-child {
    margin-bottom: 0;
  }
  .navbar-expand-xl .dropdown-menu .dropdown-menu {
    display: none;
    top: 0;
    right: auto;
    left: 100%;
    width: auto;
    margin-top: 0;
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0;
    padding-left: 0;
    border-radius: 5px;
    background-color: #ffffff;
  }
  .navbar-expand-xl .dropdown-menu.show {
    display: none;
  }
  .navbar-expand-xl .dropdown-menu.dropdown-menu-end {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .dropdown:hover > .dropdown-menu {
    display: block;
    animation: dropdown-show 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .navbar-expand-xl .dropdown-mega {
    position: static;
  }
  .navbar-expand-xl .dropdown-mega:hover > .dropdown-menu {
    display: flex;
  }
  .navbar-expand-xl .dropdown-mega .dropdown-column.dropdown-column-img {
    width: 200px;
    background-position: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
  }
  .navbar-expand-xl .dropdown-mega .dropdown-column:last-child.dropdown-column-img {
    margin-right: -0.75rem;
    margin-left: 0.75rem;
  }
  .navbar-expand-xl .dropdown-mega .dropdown-column .dropdown-header {
    padding: 10px 0 5px 20px;
    font-size: 16px;
    font-weight: 600;
  }
  .offcanvas-expand {
    transform: none !important;
    z-index: 1031;
  }
  .offcanvas-expand.offcanvas-start {
    transform: none !important;
  }
  .offcanvas-expand.offcanvas-end {
    transform: none !important;
  }
  .offcanvas-expand.offcanvas-bottom {
    transform: none !important;
  }
  .offcanvas-collapse {
    display: block;
    position: static;
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
    width: auto !important;
    max-width: 100% !important;
    height: auto !important;
    transform: none !important;
    background-color: transparent;
  }
  .offcanvas-collapse .offcanvas-header {
    display: none;
  }
  .offcanvas-collapse .offcanvas-footer {
    display: none;
  }
  .offcanvas-collapse .offcanvas-body {
    padding: 0;
    overflow: initial;
  }
  .offcanvas-enabled-start {
    padding-left: 23.5rem;
  }
  .offcanvas-enabled-end {
    padding-right: 23.5rem;
    padding-left: 0;
  }
}
@media (max-width: 1200px) {
  .offcanvas-collapse .offcanvas-body .navbar-nav {
    padding-top: 0;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .dropdown-toggle::after {
    display: none;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .nav-item {
    border-top: 0;
    border-bottom: none !important;
    margin-bottom: 20px;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .nav-link {
    color: #5a5b75;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .nav-link:hover {
    color: #2780d4;
    background-color: transparent;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .nav-link.active {
    color: #2780d4;
    background-color: transparent;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .nav-link.disabled {
    color: #9e9fb4;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .show > .nav-link {
    color: #2780d4;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .active > .nav-link {
    color: #2780d4;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .dropdown-menu li {
    display: block;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .dropdown-menu .dropdown-item {
    color: #5a5b75;
    padding: 5px 20px 5px 20px;
    margin: 2px 0px 2px 0;
    font-size: 14px;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .dropdown-menu .dropdown-item:hover {
    color: #2780d4;
    background-color: transparent;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .dropdown-menu .dropdown-item.active {
    color: #2780d4;
    background-color: transparent;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .dropdown-menu .dropdown-item.disabled {
    color: #9e9fb4;
  }
  .offcanvas-collapse .offcanvas-body .navbar-nav .dropdown-header {
    color: #4a4b65;
    border-color: #e9e9f2;
    font-size: 16px;
    font-weight: 600;
  }
}
/*=====================
    Header CSS start
==========================*/
header {
  position: absolute;
  z-index: 3;
  padding: 0;
  width: 100%;
  top: 15px;
}
header .top-bar {
  display: flex;
  align-items: center;
}
header .top-bar .top-details {
  margin-left: auto;
}
header .top-bar .top-details li a {
  color: #ededed;
  font-size: 16px;
  display: flex;
  align-items: center;
}
header .top-bar .top-details li a i {
  color: #ededed;
  margin-right: 10px;
}
header .top-bar .top-details li + li {
  margin-left: 14px;
}
header .web-logo {
  width: calc(110px + 40 * (100vw - 320px) / 1600);
}
header .web-logo h3 {
  font-size: calc(22px + 8 * (100vw - 320px) / 1600);
  font-weight: 600;
}
header .web-logo img {
  width: 150px;
}
header .nav-logo {
  display: none;
  margin-right: 20px;
}
@media (max-width: 1199px) {
  header .nav-logo {
    display: block;
  }
}
header.sticky .nav-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 0;
  background-color: #ffffff;
  box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.06);
}
header.sticky .nav-section .header-section {
  box-shadow: none;
  background-color: transparent;
}
header.sticky .nav-logo {
  display: block;
}

.nav-section {
  margin-top: 15px;
  transition: all 0.5s ease;
}
.nav-section .header-section {
  background-color: #ffffff;
  padding: calc(3px + 12 * (100vw - 320px) / 1600) calc(12px + 3 * (100vw - 320px) / 1600);
  border-radius: calc(8px + 7 * (100vw - 320px) / 1600);
  box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.06);
}

.header-section {
  display: flex;
  align-items: center;
}
.header-section .nav .nav-link {
  font-size: 16px;
  color: #222222;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 0.02em;
}

.dark-light-btn {
  margin-right: 22px;
}
@media (max-width: 576px) {
  .dark-light-btn {
    margin-right: 0;
  }
}
.dark-light-btn i {
  color: #737373;
  font-size: 18px;
}
.dark-light-btn .dark-btn {
  display: inline-block;
}
.dark-light-btn .light-btn {
  display: none;
}
.dark-light-btn.light .dark-btn {
  display: none;
}
.dark-light-btn.light .light-btn {
  display: inline-block;
}

/*=====================
    Home CSS start
==========================*/
.home-vector {
  position: relative;
  border-radius: 0;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  z-index: 1;
  padding-top: 0;
  display: flex;
  align-items: center;
  background-position: bottom;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .home-vector {
    height: 75vh;
  }
}
@media (max-width: 991px) {
  .home-vector {
    height: auto;
    padding: 170px 0 90px;
    background-image: linear-gradient(to right, #0c4393, #2780d4);
  }
}
@media (max-width: 767px) {
  .home-vector {
    padding: 140px 0 60px;
  }
}
.home-vector:after {
  position: absolute;
  content: "";
  background-image: url(../images/background/gradient-banner.png);
  width: 100%;
  height: 100%;
  background-position: bottom;
  left: -71px;
  top: 10px;
  z-index: -1;
  transform: rotate(2deg) scale(1.15);
}
@media (max-width: 1199px) {
  .home-vector:after {
    transform: rotate(-4deg) scale(2);
    left: 0;
  }
}
@media (max-width: 991px) {
  .home-vector:after {
    display: none;
  }
}
.home-vector .home-content {
  display: flex;
  align-items: center;
  height: 100%;
}
.home-vector .home-content h1 {
  color: #f9f9f9;
  margin-bottom: calc(10px + 10 * (100vw - 320px) / 1600);
  font-weight: 700;
  font-size: calc(18px + 28 * (100vw - 320px) / 1600);
  line-height: 1.3;
  text-transform: unset;
  margin-top: -11px;
}
.home-vector .home-content h1 span.effect {
  position: relative;
  z-index: 1;
}
.home-vector .home-content h1 span.effect:after {
  content: "";
  position: absolute;
  bottom: 7px;
  left: 0;
  z-index: -1;
  height: 17px;
  width: 100%;
  background-color: #fad8b0;
}
.home-vector .home-content p {
  line-height: calc(26px + 4 * (100vw - 320px) / 1600);
  color: #ededed;
}
.home-vector .home-content h3 {
  line-height: 28px;
  margin-bottom: calc(16px + 14 * (100vw - 320px) / 1600);
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  font-weight: 400;
  letter-spacing: 0.02em;
  max-width: 100%;
  color: #ededed;
}
@media (max-width: 1199px) {
  .home-vector .home-content h3 {
    max-width: 100%;
  }
}
.home-vector .home-content .line-span {
  display: block;
  width: 90px;
  height: 5px;
  background-color: #2780d4;
  margin-bottom: calc(16px + 14 * (100vw - 320px) / 1600);
}
.home-vector .home-content .bottom-sec {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.home-vector .home-content .bottom-sec a + a {
  margin-left: 15px;
}
.home-vector .home-content .bottom-sec .rating-part {
  margin-left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 14px;
}
.home-vector .home-content .bottom-sec .rating-part .rating-detail {
  margin-left: 8px;
}
.home-vector .home-content .bottom-sec .rating-part .rating-detail li svg {
  width: 18px;
  fill: #f8a512;
  stroke: #f8a512;
}
.home-vector .home-content .bottom-sec .rating-part .rating-detail li + li {
  margin-left: 4px;
}
.home-vector .home-content .bottom-sec .rating-part .rating-detail .clutch-img {
  width: 60px;
}
.home-vector .home-content .bottom-sec h2 {
  font-weight: 500;
  font-size: 29px;
  padding-top: 4px;
}
@media (max-width: 420px) {
  .home-vector .home-content .bottom-sec {
    display: block;
  }
  .home-vector .home-content .bottom-sec a {
    width: 100%;
  }
  .home-vector .home-content .bottom-sec a + a {
    margin-top: 10px;
    margin-left: 0;
  }
}
.home-vector .vector-section {
  position: relative;
  margin: 0 auto;
  width: 88%;
}
.home-vector .vector-section .girl-svg {
  position: absolute;
  left: 27%;
  bottom: -2px;
  animation: float 6s ease-in-out infinite;
}
@media (max-width: 1400px) {
  .home-vector .vector-section .girl-svg {
    width: 45%;
  }
}
.home-vector .vector-section .tab-effect {
  width: 19%;
  position: absolute;
  right: 6%;
  top: 19%;
  animation: shimmy1 3s infinite;
  animation-direction: alternate;
}
.home-vector .vector-section .tab2-effect {
  position: absolute;
  left: 53%;
  width: 24%;
  top: 4%;
  animation: shimmy 3s infinite;
  animation-direction: alternate;
}
.home-vector .vector-section .upwork-box {
  animation: up-down linear 4s;
  animation-iteration-count: infinite;
  position: absolute;
  right: 0;
  top: 40px;
}
.home-vector .bottom-arrow {
  position: absolute;
  left: 50%;
  bottom: 10px;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0px 2px 8px 0px #ddd;
}
.home-vector .bottom-arrow svg {
  stroke: #222222;
}
.home-vector.inner-home {
  background-image: none;
  padding-top: 220px;
  padding-bottom: 75px;
  background-image: linear-gradient(to right, #0c4393, #2780d4);
  height: auto;
}
.home-vector.inner-home.contact-page {
  padding-top: 220px;
  padding-bottom: 120px;
}
@media (max-width: 1199px) {
  .home-vector.inner-home.contact-page {
    padding-top: 142px;
    padding-bottom: 100px;
  }
}
@media (max-width: 1199px) {
  .home-vector.inner-home {
    padding-top: 170px;
    padding-bottom: 75px;
  }
}
@media (max-width: 991px) {
  .home-vector.inner-home {
    padding-top: 150px;
    padding-bottom: 55px;
  }
}
@media (max-width: 767px) {
  .home-vector.inner-home {
    padding-top: 128px;
    padding-bottom: 38px;
  }
}
.home-vector.inner-home::after {
  display: none;
}
.home-vector.hire-detail-section {
  background-image: none;
  background-color: #fbf3ec;
  padding-top: 240px;
  background-color: rgba(255, 255, 255, 0.83);
  background-image: url(https://image.freepik.com/free-vector/vibrant-summer-ombre-background-vector_53876-105765.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  padding-bottom: 100px;
  height: auto;
}
@media (max-width: 1199px) {
  .home-vector.hire-detail-section {
    padding-top: 200px;
    padding-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .home-vector.hire-detail-section {
    padding-top: 140px;
    padding-bottom: 40px;
  }
}

.breadcrumb-section {
  text-align: center;
}
.breadcrumb-section h2 {
  margin-top: -9px;
  color: #f9f9f9;
  font-size: calc(26px + 14 * (100vw - 320px) / 1600);
}
.breadcrumb-section h3 {
  font-size: calc(18px + 8 * (100vw - 320px) / 1600);
  margin-bottom: calc(8px + 6 * (100vw - 320px) / 1600);
  line-height: 28px;
  color: #f9f9f9;
}
.breadcrumb-section .opening-details {
  display: flex;
  align-items: center;
  margin: calc(0px + -2 * (100vw - 320px) / 1600) -15px;
  flex-wrap: wrap;
  overflow: hidden;
}
@media (max-width: 767px) {
  .breadcrumb-section .opening-details {
    margin-top: 6px;
  }
}
.breadcrumb-section .opening-details h6 {
  position: relative;
  text-transform: capitalize;
  color: rgba(237, 237, 237, 0.8);
  margin: calc(0px + 2 * (100vw - 320px) / 1600) 15px;
}
@media (max-width: 767px) {
  .breadcrumb-section .opening-details h6 {
    font-size: 14px;
  }
}
.breadcrumb-section .opening-details h6:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 10px;
  background-color: #c9c9c9;
  left: -16px;
  top: 50%;
  transform: translateY(-50%);
}
.breadcrumb-section .breadcrumb-item.active {
  color: #ededed;
}
.breadcrumb-section .breadcrumb-item + .breadcrumb-item::before {
  color: #ededed;
}
.breadcrumb-section .breadcrumb {
  justify-content: center;
  margin-bottom: 0;
  margin-top: 6px;
}
.breadcrumb-section .breadcrumb .breadcrumb-item a {
  color: #f9f9f9;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
/*=====================
    Home Page CSS start
==========================*/
.service-section {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: -120px;
}
@media (max-width: 991px) {
  .service-section {
    margin-top: 30px;
  }
}
.service-section .service-box {
  text-align: center;
  box-shadow: 0px 2px 20px rgba(123, 149, 162, 0.15);
  border-radius: 20px;
  backdrop-filter: blur(6px);
  background-color: rgba(255, 255, 255, 0.65);
  padding: 40px;
  height: 100%;
}
.service-section .service-box .service-icon {
  width: 70px;
  height: 70px;
  margin: -60px auto 20px;
  background-color: #2780d4;
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 100%;
  background: linear-gradient(27deg, rgb(42, 135, 219) 0%, rgb(16, 91, 203) 100%);
  border-radius: 28% 70% 70% 30%/30% 30% 70% 70%;
}
@media (max-width: 1199px) {
  .service-section .service-box .service-icon {
    width: 68px;
    height: 68px;
  }
}
@media (max-width: 991px) {
  .service-section .service-box .service-icon {
    margin: -40px auto 8px;
  }
}
@media (max-width: 480px) {
  .service-section .service-box .service-icon {
    width: 58px;
    height: 58px;
  }
}
.service-section .service-box svg {
  margin: 0 auto;
  width: 60%;
}
.service-section .service-box img {
  width: 56px;
  margin: 0 auto;
  filter: invert(1);
}
@media (max-width: 480px) {
  .service-section .service-box img {
    width: 50px;
  }
}
.service-section .service-box .service-content h3 {
  font-weight: 600;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
}
.service-section .service-box .service-content p {
  margin-top: calc(8px + 10 * (100vw - 320px) / 1600);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: -3px;
}
.service-section .service-box .service-content .service-list {
  margin-bottom: -5px;
}
.service-section .service-box .service-content .service-list li {
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 14px;
}
.service-section .service-box .service-content .service-list li a {
  color: #222222;
  font-size: 14px;
}
.service-section .service-box .service-content .service-list li + li {
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid #dddddd;
}
@media (max-width: 1367px) {
  .service-section .service-box {
    padding: 20px;
  }
}
@media (max-width: 1199px) {
  .service-section .service-box {
    padding: 30px;
  }
}
@media (max-width: 991px) {
  .service-section .service-box {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .service-section .service-box {
    padding: 30px;
    max-width: 94%;
    margin: 0 auto;
  }
}
@media (max-width: 460px) {
  .service-section .service-box {
    padding: 18px;
  }
}
.service-section .section-row .row {
  margin-top: -30px;
  margin-bottom: calc(-15px + -15 * (100vw - 320px) / 1600);
}
.service-section .section-row .row > div {
  padding-top: 30px;
  padding-bottom: calc(15px + 15 * (100vw - 320px) / 1600);
}

.technology-section {
  position: relative;
  z-index: 1;
}
.technology-section .nav-tabs {
  justify-content: center;
}
.technology-section .nav-tabs .nav-link {
  color: #777777;
  font-size: 18px;
  padding: 0 25px 8px;
  border: none;
  text-transform: capitalize;
}
.technology-section .nav-tabs .nav-link.active {
  background-color: transparent;
  color: #2780d4;
  border-bottom: 1px solid #2780d4;
}
.technology-section .tab-content {
  display: block;
  width: 80%;
  margin: 50px auto 0;
}
.technology-section .tab-content .tab-pane.active .tech-box, .technology-section .tab-content .tab-pane.show .tech-box {
  animation: zoomIn 300ms ease-in-out;
}
.technology-section .tech-wrap {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 15px;
  height: 100%;
  min-height: 250px;
  box-shadow: rgba(22, 31, 39, 0.05) 0px 60px 123px -25px, rgba(19, 26, 32, 0.06) 0px 35px 75px -35px;
  position: relative;
}
@media (max-width: 767px) {
  .technology-section .tech-wrap {
    padding: calc(16px + 4 * (100vw - 320px) / 1600);
  }
}
@media (max-width: 480px) {
  .technology-section .tech-wrap {
    min-height: unset;
  }
}
.technology-section .tech-wrap .wrap-title {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  margin-bottom: 20px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 15px;
}
.technology-section .tech-wrap .wrap-title h3 {
  font-weight: 600;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
}
.technology-section .tech-wrap .wrap-title img {
  width: calc(26px + 8 * (100vw - 320px) / 1600);
  margin-right: calc(10px + 5 * (100vw - 320px) / 1600);
}
.technology-section .tech-wrap .content ul {
  margin-bottom: -15px;
}
@media (max-width: 480px) {
  .technology-section .tech-wrap .content ul {
    column-count: 2;
    margin-bottom: 0;
  }
}
.technology-section .tech-wrap .content ul li {
  display: block;
  color: #777777;
  line-height: 1.7;
  margin-bottom: 15px;
}
.technology-section .tech-wrap .content ul li img {
  width: calc(18px + 4 * (100vw - 320px) / 1600);
  margin-right: calc(6px + 4 * (100vw - 320px) / 1600);
}
.technology-section .tech-wrap .content ul li a {
  color: #777777;
  font-size: calc(14px + 1 * (100vw - 320px) / 1600);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.technology-section .tech-wrap .content ul.column-2 {
  column-count: 2;
}

.why-outsource {
  position: relative;
}
.why-outsource .why-us-box {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  height: 100%;
  box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.06);
}
@media (max-width: 1199px) {
  .why-outsource .why-us-box {
    padding: 20px;
  }
}
@media (max-width: 991px) {
  .why-outsource .why-us-box {
    padding: calc(20px + 10 * (100vw - 320px) / 1600);
  }
}
@media (max-width: 380px) {
  .why-outsource .why-us-box {
    display: block;
    text-align: center;
  }
}
.why-outsource .why-us-box .whyus-content {
  width: 80%;
}
.why-outsource .why-us-box .whyus-content h3 {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
@media (max-width: 991px) {
  .why-outsource .why-us-box .whyus-content h3 {
    margin-bottom: 6px;
    font-size: 16px;
  }
}
.why-outsource .why-us-box .whyus-content p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 767px) {
  .why-outsource .why-us-box .whyus-content {
    width: 85%;
  }
}
@media (max-width: 380px) {
  .why-outsource .why-us-box .whyus-content {
    width: 100%;
  }
}
.why-outsource .why-us-box .whyus-icon {
  position: relative;
  background-color: #ffffff;
  display: inline-block;
  width: 70px;
  height: 70px;
  padding-right: 25px;
}
@media (max-width: 991px) {
  .why-outsource .why-us-box .whyus-icon {
    width: 60px;
    height: 60px;
    padding-right: 20px;
  }
}
@media (max-width: 767px) {
  .why-outsource .why-us-box .whyus-icon {
    width: 15%;
  }
}
@media (max-width: 380px) {
  .why-outsource .why-us-box .whyus-icon {
    padding-right: 0;
  }
}
.why-outsource .slick-list {
  margin: 0 -15px;
}
.why-outsource .slick-list .slick-slide > div {
  margin: 0 15px;
}

.development-section .tab-slider--nav {
  width: 100%;
  float: left;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.development-section .tab-slider--nav .tab-slider--tabs {
  display: block;
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  border-radius: 35px;
  overflow: hidden;
  background: rgba(42, 135, 219, 0.1);
  user-select: none;
}
@media (max-width: 460px) {
  .development-section .tab-slider--nav .tab-slider--tabs {
    border-radius: 0;
    background: none;
    display: flex;
    margin: 0 -5px;
    overflow: auto;
  }
}
.development-section .tab-slider--nav .tab-slider--tabs:after {
  content: "";
  width: 50%;
  background: #2780d4;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 250ms ease-in-out;
  border-radius: 35px;
}
@media (max-width: 460px) {
  .development-section .tab-slider--nav .tab-slider--tabs:after {
    display: none;
  }
}
.development-section .tab-slider--nav .tab-slider--tabs.slide:after {
  left: 50%;
}
.development-section .tab-slider--nav .tab-slider--tabs .tab-slider--trigger {
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
  color: #2780d4;
  text-transform: uppercase;
  text-align: center;
  padding: 12px 20px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  display: inline-block;
  transition: color 250ms ease-in-out;
  user-select: none;
}
@media (max-width: 460px) {
  .development-section .tab-slider--nav .tab-slider--tabs .tab-slider--trigger {
    border-radius: 35px;
    background: rgba(42, 135, 219, 0.1);
    white-space: nowrap;
    margin: 0 5px;
  }
}
.development-section .tab-slider--nav .tab-slider--tabs .tab-slider--trigger.active {
  color: #ffffff;
}
@media (max-width: 460px) {
  .development-section .tab-slider--nav .tab-slider--tabs .tab-slider--trigger.active {
    background-color: #2780d4;
  }
}
.development-section .development-box {
  background-color: #ffffff;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: calc(25px + 15 * (100vw - 320px) / 1600) calc(20px + 10 * (100vw - 320px) / 1600);
  transition: all 0.5s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.development-section .development-box .number-effect {
  content: "";
  position: absolute;
  font-size: calc(45px + 25 * (100vw - 320px) / 1600);
  right: 10px;
  bottom: calc(-24px + -6 * (100vw - 320px) / 1600);
  font-weight: 900;
  color: #e9f3fb;
}
.development-section .development-box .development-icon h1 {
  color: #2780d4;
  font-size: 30px;
  font-weight: 600;
}
.development-section .development-box .development-icon img {
  width: auto;
}
.development-section .development-box .development-content {
  margin-top: calc(10px + 4 * (100vw - 320px) / 1600);
}
.development-section .development-box .development-content h3 {
  text-transform: capitalize;
  margin-bottom: calc(10px + 4 * (100vw - 320px) / 1600);
  font-weight: 600;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
}
.development-section .development-box .development-content p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: -3px;
  width: 86%;
}
.development-section .development-box:hover {
  transform: translateY(-10px);
  transition: all 0.5s ease;
}

.testimonial-section {
  position: relative;
}
.testimonial-section .testimonial-row {
  position: relative;
}
.testimonial-section .testimonial-row::after {
  opacity: 0;
  content: "";
  position: absolute;
  left: -30px;
  height: 100%;
  top: 4px;
  width: 100px;
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0) 4%, rgb(251, 243, 237) 97%, rgb(251, 243, 237) 100%);
  z-index: 1;
}
.testimonial-section .testimonial-row::before {
  opacity: 0;
  content: "";
  position: absolute;
  right: -30px;
  height: 100%;
  top: 4px;
  width: 100px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0) 4%, rgb(251, 243, 237) 97%, rgb(251, 243, 237) 100%);
  z-index: 1;
}
.testimonial-section .slick-list {
  margin: 0 calc(-7px + -8 * (100vw - 320px) / 1600);
}
.testimonial-section .slick-list .slick-slide > div {
  margin: 0 calc(7px + 8 * (100vw - 320px) / 1600);
}
.testimonial-section .slick-dots {
  position: relative;
}
.testimonial-section .slick-dots li button:before {
  font-size: 12px;
  color: #2780d4;
}
.testimonial-section .testimonial-box {
  padding: 10px;
  border-radius: 20px;
  background-color: #ffffff;
  display: block;
  box-shadow: rgba(22, 31, 39, 0.05) 0px 0 123px -25px, rgba(19, 26, 32, 0.06) 0 0 75px -35px;
}
.testimonial-section .testimonial-box .testimonial-img {
  border-radius: 16px;
  position: relative;
}
.testimonial-section .testimonial-box .testimonial-content h3 {
  color: #222222;
  margin-top: 15px;
}
.testimonial-section .testimonial-box .testimonial-content h5 {
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5;
}
.testimonial-section .video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0.5);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}
.testimonial-section .video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #2780d4;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}
.testimonial-section .video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}
.testimonial-section .video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 50%;
  transition: all 200ms;
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes up-down {
  0% {
    transform: translateY(22px);
  }
  50% {
    transform: translateY(12px);
  }
  100% {
    transform: translateY(22px);
  }
}
.contact-us-section .title h2 {
  font-size: calc(22px + 18 * (100vw - 320px) / 1600);
}
.contact-us-section .title span {
  font-size: calc(22px + 18 * (100vw - 320px) / 1600);
}
.contact-us-section .top-effect .dot-effect {
  position: absolute;
  left: -60px;
  top: 0;
  z-index: -1;
}
.contact-us-section .top-effect .bg-effect {
  position: absolute;
  left: -30%;
  top: -15%;
  z-index: -1;
  width: 70%;
  transform: rotate(45deg);
}
@media (max-width: 991px) {
  .contact-us-section .contact-details {
    margin-bottom: 50px;
  }
}
.contact-us-section .contact-details img {
  width: 80%;
}
.contact-us-section .contact-details .contact-list li {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1.8;
}
.contact-us-section .contact-details .contact-list li svg {
  margin-right: 8px;
}
.contact-us-section .contact-details .social-list {
  margin-top: 20px;
}
.contact-us-section .contact-details .social-list ul {
  display: flex;
  margin: 0 -8px;
}
.contact-us-section .contact-details .social-list ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin: 0 8px;
}
.contact-us-section .contact-details .social-list ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-us-section .contact-details .social-list ul li i {
  font-size: 20px;
  color: #ffffff;
}
.contact-us-section .contact-details .social-list ul li.facebook {
  background-color: #4867aa;
}
.contact-us-section .contact-details .social-list ul li.twitter {
  background-color: #5da9dd;
}
.contact-us-section .contact-details .social-list ul li.instagram {
  background-color: #c18af7;
}
.contact-us-section .nav-tabs {
  flex-wrap: unset;
}
.contact-us-section .nav-tabs .nav-link {
  border: none;
  color: #777777;
  font-size: 16px;
  padding: 10px 35px;
  border-radius: 10px 10px 0 0;
}
@media (max-width: 420px) {
  .contact-us-section .nav-tabs .nav-link {
    font-size: 14px;
    padding: 10px 22px;
  }
}
.contact-us-section .nav-tabs .nav-link.active {
  background-color: #2780d4;
  color: #ffffff;
  border: none;
  background-size: 300% 100%;
  background-image: linear-gradient(to right, #0c4393, #2780d4, #2780d4, #0c4393);
}
.contact-us-section .nav-tabs .nav-link.active i {
  color: #ffffff;
}
.contact-us-section .contact-box {
  width: 100%;
  margin: 0 auto calc(-200px + -80 * (100vw - 320px) / 1600);
  padding: calc(15px + 25 * (100vw - 320px) / 1600);
  box-shadow: 0 0 50px rgba(23, 32, 90, 0.06);
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  border-radius: 20px;
}
.contact-us-section .contact-box .contact-details ul {
  margin: 0 0 calc(-20px + -10 * (100vw - 320px) / 1600);
}
.contact-us-section .contact-box .contact-details ul li {
  display: flex;
  align-items: center;
  margin: calc(20px + 10 * (100vw - 320px) / 1600) 0;
}
@media (max-width: 576px) {
  .contact-us-section .contact-box .contact-details ul li {
    align-items: flex-start;
  }
}
.contact-us-section .contact-box .contact-details ul li .contact-icon {
  width: calc(48px + 10 * (100vw - 320px) / 1600);
  height: calc(48px + 10 * (100vw - 320px) / 1600);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background: linear-gradient(27deg, #2a87db 0%, #105bcb 100%);
  border-radius: 28% 70% 70% 30%/30% 30% 70% 70%;
}
.contact-us-section .contact-box .contact-details ul li .contact-icon svg {
  stroke: white;
}
.contact-us-section .contact-box .contact-details ul li .contact-content {
  width: 70%;
}
.contact-us-section .contact-box .contact-details ul li .contact-content h5 {
  font-weight: 600;
  margin-bottom: calc(5px + 5 * (100vw - 320px) / 1600);
  color: #222222;
  font-size: calc(15px + 2 * (100vw - 320px) / 1600);
}
.contact-us-section .contact-box .contact-details ul li .contact-content h6 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.contact-us-section.contact-page .title {
  margin-top: calc(20px + 30 * (100vw - 320px) / 1600);
}
.contact-us-section.contact-page .title .title-effect {
  font-size: calc(42px + 33 * (100vw - 320px) / 1600);
}
.contact-us-section.contact-page .title h2 {
  font-size: calc(22px + 26 * (100vw - 320px) / 1600);
}
.contact-us-section.contact-page .title span {
  font-size: calc(22px + 26 * (100vw - 320px) / 1600);
}
.contact-us-section.contact-page .title p {
  width: 80%;
  margin-bottom: calc(0px + 40 * (100vw - 320px) / 1600);
}

footer {
  position: relative;
  padding-top: calc(180px + 70 * (100vw - 320px) / 1600) !important;
  background-image: url(../images/background/footer.png);
  background-repeat: no-repeat;
  background-size: cover;
}
footer .footer-logo h3 {
  font-weight: 600;
  margin-bottom: calc(12px + 10 * (100vw - 320px) / 1600);
  font-size: 34px;
  color: #2780d4;
}
footer .footer-logo img {
  width: calc(120px + 40 * (100vw - 320px) / 1600);
  margin-bottom: calc(12px + 10 * (100vw - 320px) / 1600);
}
footer .sub-title {
  width: 100%;
}
footer .footer-title {
  margin-bottom: 38px;
  position: relative;
  padding-bottom: 4px;
}
@media (max-width: 767px) {
  footer .footer-title {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  footer .footer-title:after {
    content: "";
    position: absolute;
    border-bottom: 1px solid #dddddd;
  }
}
footer .footer-title h4 {
  color: #f9f9f9;
  text-transform: capitalize;
  font-weight: 600;
}
@media (max-width: 767px) {
  footer .footer-title h4 {
    font-size: 16px;
  }
}
footer .footer-title:after {
  content: "";
  position: absolute;
  width: 38px;
  height: 2px;
  background-color: #4c9be1;
  bottom: -16px;
}
@media (max-width: 767px) {
  footer .footer-title:after {
    display: none;
  }
}
footer .footer-title.active .according-menu::before {
  content: "\f077";
}
footer .footer-title .according-menu {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
footer .footer-title .according-menu::before {
  content: "\f078";
  position: absolute;
  right: 2px;
  top: 3px;
  font-size: 12px;
  width: 24px;
  height: 24px;
  background-color: rgba(39, 128, 212, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #2780d4;
}
footer .sub-footer {
  overflow: hidden;
}
@media (max-width: 767px) {
  footer .sub-footer {
    text-align: center;
  }
}
footer .sub-footer p {
  font-size: 14px;
  font-weight: 500;
  color: #f9f9f9;
}
footer .sub-footer p span {
  color: #f9f9f9;
  font-weight: 600;
}
footer .subfooter-content {
  text-align: right;
  margin-left: 8px;
  margin-right: 8px;
}
@media (max-width: 767px) {
  footer .subfooter-content {
    text-align: center;
    margin-top: 10px;
  }
}
footer .subfooter-content li {
  margin-left: 0;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 12px;
}
footer .subfooter-content li a {
  color: #f9f9f9;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
}
footer .subfooter-content li + li {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #3e8cdf;
}
@media (max-width: 767px) {
  footer .subfooter-content li + li {
    margin-left: 0;
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (max-width: 767px) {
  footer .footer-contant {
    padding-top: 20px;
  }
}
footer .footer-contant p {
  font-size: 14px;
  width: 85%;
  line-height: 1.7;
  color: #f9f9f9;
}
@media (max-width: 767px) {
  footer .footer-contant p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
  }
}
footer .footer-contant li {
  display: block;
  letter-spacing: 0.02em;
  color: #f9f9f9;
  font-size: 14px;
  font-weight: 500;
}
footer .footer-contant li i {
  margin-right: 12px;
  color: #f9f9f9;
}
footer .footer-contant li a {
  color: #f9f9f9;
  font-weight: 500;
}
footer .footer-contant li + li {
  margin-top: 15px;
}
footer .sub-footer .container {
  border-top: 1px solid #3e8cdf;
  padding: 26px 0;
  margin-top: calc(20px + 50 * (100vw - 320px) / 1600);
}
footer .row .col {
  display: flex;
  justify-content: center;
}
@media (max-width: 991px) {
  footer .row .col {
    flex: 0 0 auto;
    width: 50%;
    justify-content: start;
  }
}
@media (max-width: 767px) {
  footer .row .col {
    width: 100%;
  }
}
footer.no-bg {
  padding-top: calc(40px + 50 * (100vw - 320px) / 1600) !important;
  background-image: linear-gradient(to right, #0c4393, #2780d4);
}

.effect-right {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.effect-right.effect-sm {
  transform: scale(0.6);
  transform-origin: right top;
}

.effect-left {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.effect-left img {
  transform: rotate(180deg);
}

.shape1 {
  position: absolute;
  left: 5%;
  top: 2%;
  z-index: 1;
}

.shape2 {
  position: absolute;
  left: 4%;
  top: 1%;
}

.shape3 {
  position: absolute;
  right: 5%;
  top: 60px;
  z-index: 1;
  transform: rotate(75deg);
}

.shape4 {
  position: absolute;
  right: 4%;
  top: 40px;
  transform: rotate(75deg);
}

/*=====================
    about us CSS start
==========================*/
.about-detail p {
  font-size: 17px;
  color: #777777;
  margin-bottom: 15px;
}
.about-detail p:last-child {
  margin-bottom: 0;
}

/*=====================
    Hire Page CSS start
==========================*/
.hire-home.contact-us-section .contact-content {
  background-color: transparent;
  padding: 0;
  box-shadow: none;
}

.why-outsource.hire-page {
  background-color: transparent;
  overflow: hidden;
}
@media (max-width: 767px) {
  .why-outsource.hire-page {
    margin-top: 30px;
  }
}
.why-outsource.hire-page .content-section {
  margin-bottom: 0;
}
.why-outsource.hire-page .image-section {
  position: relative;
}
.why-outsource.hire-page .image-section .top-effect {
  position: absolute;
}
.why-outsource.hire-page .image-section .img1 {
  position: absolute;
  left: 13%;
  width: 46%;
  border-radius: 15px;
  box-shadow: 0 25px 18px rgba(0, 0, 0, 0.07);
}
.why-outsource.hire-page .image-section .img2 {
  position: absolute;
  top: 30%;
  left: 45%;
  width: 46%;
  border-radius: 15px;
  box-shadow: 0 25px 18px rgba(0, 0, 0, 0.07);
}
.why-outsource.hire-page .image-section .img3 {
  position: absolute;
  bottom: 30px;
  width: 41%;
  left: 22%;
  z-index: -1;
  width: 46%;
  border-radius: 15px;
  box-shadow: 0 25px 18px rgba(0, 0, 0, 0.07);
}
.why-outsource.hire-page .why-us-box {
  margin-left: 30px;
  align-items: center;
  box-shadow: 0px 2px 20px rgba(123, 149, 162, 0.2);
}
@media (max-width: 1400px) {
  .why-outsource.hire-page .why-us-box {
    padding: 22px;
  }
}
@media (max-width: 1199px) {
  .why-outsource.hire-page .why-us-box {
    padding: 30px;
  }
}
@media (max-width: 991px) {
  .why-outsource.hire-page .why-us-box {
    padding: 20px;
  }
}
@media (max-width: 380px) {
  .why-outsource.hire-page .why-us-box {
    margin: 0 5px;
  }
}
.why-outsource.hire-page .why-us-box .whyus-icon {
  background-color: #e5f0fb;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 26px;
  padding: 18px;
  border-radius: 15px;
  margin-left: -50px;
  width: 70px;
  height: 70px;
  border-radius: 28% 70% 70% 30%/30% 30% 70% 70%;
}
@media (max-width: 1400px) {
  .why-outsource.hire-page .why-us-box .whyus-icon {
    margin-right: 16px;
  }
}
@media (max-width: 1199px) {
  .why-outsource.hire-page .why-us-box .whyus-icon {
    width: 60px;
    height: 60px;
    padding: 14px;
  }
}
@media (max-width: 380px) {
  .why-outsource.hire-page .why-us-box .whyus-icon {
    margin: 0 auto 15px;
  }
}
.why-outsource.hire-page .why-us-box .whyus-content {
  width: 82%;
}
@media (max-width: 767px) {
  .why-outsource.hire-page .why-us-box .whyus-content {
    width: 92%;
  }
}
@media (max-width: 380px) {
  .why-outsource.hire-page .why-us-box .whyus-content {
    width: 100%;
  }
}
@media (max-width: 1199px) {
  .why-outsource.hire-page .why-us-box .whyus-content h3 {
    font-size: calc(16px + 2 * (100vw - 320px) / 1600);
    margin-bottom: calc(8px + 4 * (100vw - 320px) / 1600);
  }
}
.why-outsource.hire-page .why-us-box .whyus-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.model-box {
  padding: 30px 25px;
  border: 1px dashed #ddd;
  border-radius: 20px;
  box-shadow: 0 0 8px #f7f7f7;
  background-color: #ffffff;
  z-index: 1;
  position: relative;
}
@media (max-width: 1199px) {
  .model-box {
    padding: 20px 15px;
    border-radius: 18px;
  }
}
.model-box .modal-top {
  text-align: center;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .model-box .modal-top {
    margin-bottom: 15px;
  }
}
.model-box .modal-top .model-title {
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 6px;
}
@media (max-width: 1199px) {
  .model-box .modal-top .model-title {
    font-size: 18px;
    margin-bottom: 3px;
  }
}
.model-box .duration-box {
  padding: 20px 25px;
  border-radius: 10px;
  background-color: #eef5fc;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
}
.model-box .duration-box i {
  font-size: 50px;
  position: absolute;
  right: 20px;
  opacity: 0.03;
  bottom: 20px;
}
.model-box .duration-box img {
  width: 50px;
  position: absolute;
  right: 20px;
  opacity: 0.1;
  bottom: 20px;
}
@media (max-width: 1400px) {
  .model-box .duration-box img {
    width: 40px;
    right: 10px;
    bottom: 20px;
  }
}
.model-box .duration-box span {
  font-size: 30px;
  color: #000000;
  font-weight: 600;
  margin-right: 15px;
}
@media (max-width: 1400px) {
  .model-box .duration-box span {
    font-size: 24px;
    margin-right: 10px;
  }
}
@media (max-width: 1199px) {
  .model-box .duration-box span {
    font-size: 20px;
  }
}
.model-box .duration-box h4 {
  margin-bottom: 5px;
  color: #222222;
  font-weight: 600;
}
@media (max-width: 1400px) {
  .model-box .duration-box h4 {
    margin-bottom: 3px;
    font-weight: 600;
    font-size: 16px;
  }
}
@media (max-width: 1400px) {
  .model-box .duration-box h6 {
    font-size: 14px;
  }
}
.model-box .duration-box.pink-shade {
  background-color: #fbf0e9;
}
.model-box .duration-box.purple-shade {
  background-color: #f8eefc;
}
.model-box .feature-section {
  padding-left: 15px;
  margin: calc(20px + 20 * (100vw - 320px) / 1600) 0 0;
}
@media (max-width: 1199px) {
  .model-box .feature-section {
    margin: 0;
  }
}
@media (max-width: 576px) {
  .model-box .feature-section {
    padding-left: 3px;
  }
}
.model-box .feature-section ul {
  padding-inline-start: 40px;
}
@media (max-width: 1400px) {
  .model-box .feature-section ul {
    padding-inline-start: 30px;
  }
}
.model-box .feature-section li {
  display: block;
  color: #777777;
  position: relative;
  font-size: 16px;
}
@media (max-width: 1400px) {
  .model-box .feature-section li {
    font-size: 14px;
  }
}
.model-box .feature-section li:after {
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  position: absolute;
  font-size: 16px;
  color: #777777;
  font-weight: 900;
  left: -34px;
}
@media (max-width: 1400px) {
  .model-box .feature-section li:after {
    left: -28px;
  }
}
.model-box .feature-section li + li {
  margin-top: calc(10px + 5 * (100vw - 320px) / 1600);
}
.model-box .btn-theme {
  text-transform: capitalize;
  border-radius: 6px;
}

.comparison-section {
  position: relative;
  z-index: 1;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.comparison-section ::-webkit-scrollbar {
  height: 5px;
}
.comparison-section ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.comparison-section ::-webkit-scrollbar-thumb {
  background: #2780d4;
}
.comparison-section ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.comparison-section .comparison-table {
  background-color: #ffffff;
  margin-top: 15px;
  font-size: 16px;
  position: relative;
  box-shadow: 0px 2px 20px rgba(123, 149, 162, 0.2);
}
.comparison-section .comparison-table table th {
  padding: 20px 20px !important;
  letter-spacing: 0.02em;
  color: #222222;
}
.comparison-section .comparison-table table th:nth-child(2) {
  color: #ffffff;
  font-size: 18px;
  padding: 14px !important;
  font-weight: 600;
  background-color: #2780d4 !important;
}
.comparison-section .comparison-table table th:nth-child(2) img {
  width: 140px;
}
.comparison-section .comparison-table table tr:last-child td {
  border-bottom: none;
}
.comparison-section .comparison-table table td,
.comparison-section .comparison-table table th {
  text-align: center;
  min-width: 220px;
  border-color: #f3f3f3 !important;
  padding: 13px 20px;
  vertical-align: middle;
}
@media (max-width: 1199px) {
  .comparison-section .comparison-table table td,
  .comparison-section .comparison-table table th {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .comparison-section .comparison-table table td,
  .comparison-section .comparison-table table th {
    min-width: 260px;
  }
}
@media (max-width: 576px) {
  .comparison-section .comparison-table table td,
  .comparison-section .comparison-table table th {
    min-width: 220px;
  }
}
.comparison-section .comparison-table table td:first-child,
.comparison-section .comparison-table table th:first-child {
  text-align: left;
}
.comparison-section .comparison-table table td:nth-child(2),
.comparison-section .comparison-table table th:nth-child(2) {
  box-shadow: 0px 10px 15px #e8e8e8;
  border-color: transparent !important;
  background-color: #f0f8ff;
}

.faq-section {
  position: relative;
}
.faq-section .faq-container {
  position: relative;
  z-index: 1;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media (max-width: 767px) {
  .faq-section .faq-container .row.g-4 > div:nth-child(2) {
    margin-top: calc(15px + 10 * (100vw - 320px) / 1600);
  }
}
.faq-section .faq-container ::-webkit-scrollbar {
  width: 5px;
}
.faq-section .faq-container ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.faq-section .faq-container ::-webkit-scrollbar-thumb {
  background: #2780d4;
}
.faq-section .faq-container ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.faq-section .faq-container .accordion {
  overflow: auto;
  height: 450px;
}
@media (max-width: 767px) {
  .faq-section .faq-container .accordion {
    height: auto;
  }
}
.faq-section .top-effect {
  right: -100px;
  position: absolute;
  bottom: -120px;
  z-index: -1;
  margin-left: auto;
  transform: scale(0.8);
}
.faq-section .accordion {
  padding: 2px;
}
.faq-section .accordion .accordion-item {
  position: relative;
  border: none;
  background-color: #ffffff;
  backdrop-filter: blur(6px);
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.12);
}
.faq-section .accordion .accordion-item .accordion-body {
  padding: 0 1.25rem 1rem 1.25rem;
}
.faq-section .accordion .accordion-item .accordion-body p {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.02em;
}
.faq-section .accordion .accordion-item .accordion-button {
  box-shadow: none;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  color: #222222;
  background-color: #ffffff;
  padding-right: calc(52px + -7 * (100vw - 320px) / 1600);
  line-height: calc(22px + 4 * (100vw - 320px) / 1600);
  font-weight: 600;
}
@media (max-width: 576px) {
  .faq-section .accordion .accordion-item .accordion-button {
    padding-right: 39px;
  }
}
.faq-section .accordion .accordion-item .accordion-button:after {
  position: absolute;
  right: 20px;
}
@media (max-width: 576px) {
  .faq-section .accordion .accordion-item .accordion-button:after {
    right: 15px;
  }
}
.faq-section .accordion .accordion-item + .accordion-item {
  margin-top: calc(15px + 10 * (100vw - 320px) / 1600);
}

.hire-detail-sec h3.hire-title {
  margin-bottom: calc(14px + 6 * (100vw - 320px) / 1600);
  font-size: 20px;
  font-weight: 600;
}
.hire-detail-sec .hire-detail-row {
  margin-top: 25px;
}
.hire-detail-sec .hire-detail {
  margin-bottom: -14px;
}
.hire-detail-sec .hire-detail .hire-title {
  font-size: calc(16px + 8 * (100vw - 320px) / 1600);
}
.hire-detail-sec .hire-detail p {
  margin-bottom: 14px;
}
.hire-detail-sec .hire-box {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 2px 20px rgba(123, 149, 162, 0.2);
  padding: 25px 65px 25px 25px;
  position: relative;
  overflow: hidden;
}
.hire-detail-sec .hire-box h3 {
  margin-bottom: calc(5px + 7 * (100vw - 320px) / 1600);
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  font-weight: 600;
}
.hire-detail-sec .hire-box p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.hire-detail-sec .hire-box img {
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%) rotate(4deg);
  opacity: 0.4;
  width: 60px;
  height: 60px;
  object-fit: cover;
  object-position: left;
  transition: all 0.5s ease;
}
.hire-detail-sec .hire-box:hover img {
  transform: translateY(-50%) rotate(-4deg);
  transition: all 0.5s ease;
}

.single-tech-section .single-tech-listing .row {
  box-shadow: 0px 2px 20px rgba(123, 149, 162, 0.2);
  padding: 22px;
  background-color: #ffffff;
}
@media (max-width: 576px) {
  .single-tech-section .single-tech-listing .row {
    margin: 0 15px;
  }
}
.single-tech-section .single-tech-listing .row > div {
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}
@media (max-width: 420px) {
  .single-tech-section .single-tech-listing .row > div {
    width: 50%;
  }
}
.single-tech-section .single-tech-listing .row > div:nth-last-child(-n+4) {
  border-bottom: none;
}
.single-tech-section .single-tech-listing .row > div:nth-last-child(4) {
  border-left: 1px solid #ededed;
}
.single-tech-section .single-tech-listing .row > div:nth-child(6), .single-tech-section .single-tech-listing .row > div:nth-child(12) {
  border-right: none;
}
.single-tech-section .single-tech-listing ul {
  display: flex;
  flex-wrap: wrap;
}
.single-tech-section .single-tech-listing .single-box {
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}
@media (max-width: 991px) {
  .single-tech-section .single-tech-listing .single-box {
    padding: 15px;
  }
}
.single-tech-section .single-tech-listing .single-box h3 {
  font-size: 18px;
  font-weight: 600;
}
.single-tech-section .single-tech-listing .single-box img {
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .single-tech-section .single-tech-listing .single-box img {
    margin-bottom: 8px;
  }
}
@media (max-width: 576px) {
  .single-tech-section .single-tech-listing .single-box img {
    width: 30px;
    height: auto;
  }
}
.single-tech-section .single-tech-listing .single-box h4 {
  color: #777777;
  font-size: 16px;
}
@media (max-width: 767px) {
  .single-tech-section .single-tech-listing .single-box h4 {
    font-size: 14px;
  }
}

.hire-note {
  border: 1px dashed #2a87db;
  margin-top: 50px;
  padding: 20px;
  background-color: #eef5fc;
  border-radius: 14px;
}
.hire-note h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}
.hire-note .row {
  margin-top: 20px;
}

.dot-effect {
  position: absolute;
  z-index: -1;
}
.dot-effect.bottom-right {
  right: -40px;
  bottom: -40px;
}
.dot-effect.bottom-left {
  left: -40px;
  bottom: -40px;
}

/*=====================
    Contact Us CSS start
==========================*/
.contact-top-details {
  margin-top: -98px;
  position: relative;
  z-index: 1;
}
.contact-top-details .contact-box {
  background-color: #ffffff;
  border-radius: 22px;
  text-align: center;
  padding: 36px 20px;
  box-shadow: rgba(22, 31, 39, 0.05) 0px 60px 123px -25px, rgba(19, 26, 32, 0.06) 0px 35px 75px -35px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-top-details .contact-box > div {
  margin-bottom: -4px;
}
@media (max-width: 991px) {
  .contact-top-details .contact-box {
    padding: 26px 10px;
  }
}
.contact-top-details .contact-box i {
  font-size: 50px;
  color: #2780d4;
  margin-bottom: 25px;
}
@media (max-width: 991px) {
  .contact-top-details .contact-box i {
    font-size: 40px;
    margin-bottom: 16px;
  }
}
.contact-top-details .contact-box h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}
.contact-top-details.career-page .contact-box {
  padding: calc(22px + 24 * (100vw - 320px) / 1600) calc(20px + 20 * (100vw - 320px) / 1600);
}
.contact-top-details.career-page .contact-box h3 {
  font-size: calc(16px + 4 * (100vw - 320px) / 1600);
  margin-bottom: calc(4px + 8 * (100vw - 320px) / 1600);
}
.contact-top-details.about-page img {
  border-radius: 10px;
  overflow: hidden;
}

.contact-vector {
  position: relative;
}
.contact-vector .notification-svg {
  position: absolute;
  right: 24%;
  top: -4%;
  animation: ring 4s 0.7s ease-in-out infinite;
}
@media (max-width: 1400px) {
  .contact-vector .notification-svg {
    right: 13%;
  }
}
.contact-vector .message-svg {
  position: absolute;
  right: 42%;
  top: 9%;
  animation: msg 4s ease-in-out infinite;
}
@media (max-width: 1400px) {
  .contact-vector .message-svg {
    right: 36%;
  }
}
.contact-vector .tab-svg {
  position: absolute;
  left: 7%;
  animation: tab 4s ease-in-out infinite;
}
@media (max-width: 1199px) {
  .contact-vector .tab-svg {
    left: 6%;
    width: 22%;
    top: 6%;
  }
}

/*=====================
    Career CSS start
==========================*/
.current-opening-section {
  position: relative;
}
.current-opening-section li {
  width: 100%;
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0 0 8px #dddddd;
  border-radius: 12px;
  position: relative;
}
@media (max-width: 767px) {
  .current-opening-section li {
    padding: 20px;
  }
}
.current-opening-section li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f1f9ff;
  z-index: -1;
  bottom: -10px;
  right: -10px;
  border-radius: 12px;
}
.current-opening-section li + li {
  margin-top: 30px;
}
.current-opening-section .opening-box {
  position: relative;
}
.current-opening-section .opening-box h3 {
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
  font-weight: 600;
  margin-bottom: 8px;
  line-height: 20px;
}
.current-opening-section .opening-box .opening-details {
  display: flex;
  align-items: center;
  margin: calc(0px + -2 * (100vw - 320px) / 1600) -15px;
  flex-wrap: wrap;
  overflow: hidden;
}
@media (max-width: 767px) {
  .current-opening-section .opening-box .opening-details {
    margin-top: 6px;
  }
}
.current-opening-section .opening-box .opening-details h6 {
  position: relative;
  text-transform: capitalize;
  margin: calc(0px + 2 * (100vw - 320px) / 1600) 15px;
}
@media (max-width: 767px) {
  .current-opening-section .opening-box .opening-details h6 {
    font-size: 14px;
  }
}
.current-opening-section .opening-box .opening-details h6:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 10px;
  background-color: #c9c9c9;
  left: -16px;
  top: 50%;
  transform: translateY(-50%);
}
.current-opening-section .opening-box .btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  min-width: max-content;
  padding: 8px 32px;
}
@media (max-width: 991px) {
  .current-opening-section .opening-box .btn {
    position: relative;
    top: 0;
    transform: unset;
    margin-top: 20px;
  }
}

.perks-section.why-outsource .why-us-box {
  display: block;
}
@media (max-width: 576px) {
  .perks-section.why-outsource .why-us-box {
    text-align: center;
  }
}
.perks-section.why-outsource .why-us-box .whyus-icon {
  background-color: transparent;
}
@media (max-width: 767px) {
  .perks-section.why-outsource .why-us-box .whyus-icon {
    width: calc(50px + 10 * (100vw - 320px) / 1600);
    height: calc(50px + 10 * (100vw - 320px) / 1600);
  }
}
@media (max-width: 576px) {
  .perks-section.why-outsource .why-us-box .whyus-icon {
    padding-right: 0;
    margin-bottom: 15px;
  }
}
.perks-section.why-outsource .why-us-box .whyus-content {
  width: 100%;
}
.perks-section.why-outsource .why-us-box .whyus-content p {
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  overflow: unset;
}

.career-details-section h3 {
  font-size: calc(18px + 2 * (100vw - 320px) / 1600);
}
.career-details-section p {
  line-height: 28px;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  color: #777777;
}
.career-details-section .career-para {
  padding-inline-start: 20px;
}
.career-details-section .career-para li {
  display: list-item;
  list-style: disc;
  line-height: calc(28px + 4 * (100vw - 320px) / 1600);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  color: #777777;
}

.career-detail-sidebar {
  background-color: #eef5fb;
  padding: calc(18px + 7 * (100vw - 320px) / 1600);
}
.career-detail-sidebar h4.title-career {
  color: #222222;
  font-weight: 700;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 5px;
}
.career-detail-sidebar ul.detail-ul {
  margin-top: calc(10px + 10 * (100vw - 320px) / 1600);
}
.career-detail-sidebar ul.detail-ul li {
  line-height: 22px;
  margin-top: 10px;
  font-size: 16px;
}
.career-detail-sidebar ul.detail-ul li span {
  font-weight: 600;
}
.career-detail-sidebar .current-openings {
  margin-top: calc(10px + 5 * (100vw - 320px) / 1600);
}
.career-detail-sidebar .current-openings li {
  line-height: 22px;
  margin-top: 10px;
  font-size: 16px;
  display: block;
}
.career-detail-sidebar .current-openings li a {
  font-size: 14px;
  color: #222222;
}
.career-detail-sidebar .current-openings li span {
  font-size: 12px;
  color: #777777;
}
.career-detail-sidebar .social-share li {
  margin-right: 6px;
}
.career-detail-sidebar .social-share li a {
  color: #2378cb;
  font-size: 22px;
}

/*=====================
    Responsive CSS start
==========================*/
@media (max-width: 1199px) {
  .single-tech-section .single-tech-listing .row > div:nth-child(5n+5) {
    border-right: none;
  }
  .single-tech-section .single-tech-listing .row > div:nth-child(6) {
    border-right: 1px solid #ededed !important;
  }
  .single-tech-section .single-tech-listing .row > div:nth-last-child(2), .single-tech-section .single-tech-listing .row > div:nth-last-child(3) {
    border-bottom: 1px solid #ededed !important;
  }
  .single-tech-section .single-tech-listing .row > div:last-child {
    border-left: 1px solid #ededed;
    border-top: 1px solid #ededed;
  }
}
@media (max-width: 991px) {
  .single-tech-section .single-tech-listing .row > div:nth-child(4n+4) {
    border-right: 0;
  }
  .single-tech-section .single-tech-listing .row > div:nth-child(5n+5) {
    border-right: 1px solid #ededed;
  }
  .single-tech-section .single-tech-listing .row > div:last-child {
    border-top: none;
    border-left: none;
  }
  .single-tech-section .single-tech-listing .row > div:nth-last-child(-n+4) {
    border-bottom: none !important;
  }
  .single-tech-section .single-tech-listing .row > div:nth-child(4) {
    border-left: none;
  }
}
@media (max-width: 576px) {
  .single-tech-section .single-tech-listing .row > div:nth-child(4n+4) {
    border-right: 1px solid #ededed;
  }
  .single-tech-section .single-tech-listing .row > div:nth-child(3n+3) {
    border-right: none !important;
  }
  .single-tech-section .single-tech-listing .row > div:nth-last-child(-n+4) {
    border-bottom: 1px solid #ededed !important;
  }
  .single-tech-section .single-tech-listing .row > div:last-child {
    border-bottom: none !important;
  }
}
@media (max-width: 480px) {
  .col-xs-6 {
    width: 100%;
  }
}
@media (max-width: 420px) {
  .single-tech-section .single-tech-listing .row > div:nth-child(1n+2) {
    border-right: 1px solid #ededed !important;
  }
  .single-tech-section .single-tech-listing .row > div:nth-child(2n+2) {
    border-right: none !important;
  }
  .single-tech-section .single-tech-listing .row > div:last-child {
    border-left: none !important;
  }
  .single-tech-section .single-tech-listing .row > div:nth-last-child(2) {
    border-bottom: none !important;
  }
}