/*=====================
    Header CSS start
==========================*/
header {
    position: absolute;
    z-index: 3;
    padding: 0;
    width: 100%;
    top: 15px;
    .top-bar {
        display: flex;
        align-items: center;
        .top-details {
            margin-left: auto;
            li {
                a {
                    color: $light-content;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    i {
                        color: $light-content;
                        margin-right: 10px;
                    }
                }
                + li {
                    margin-left: 14px;
                }
            }
        }
    }
    .web-logo {
        width: calc(110px + (150 - 110) * ((100vw - 320px) / (1920 - 320)));
        h3 {
            font-size: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
        }
        img {
            width: 150px;
        }
    }
    .nav-logo {
        display: none;
        margin-right: 20px;
        @media (max-width: 1199px) {
            display: block;
        }
    }
    &.sticky {
        .nav-section {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            margin-top: 0;
            background-color: $white;
            box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.06);
            .header-section {
                box-shadow: none;
                background-color: transparent;
            }
        }
        .nav-logo {
            display: block;
        }
    }
}

.nav-section {
    margin-top: 15px;
    transition: all 0.5s ease;
    .header-section {
        background-color: $white;
        padding: calc(3px + (15 - 3) * ((100vw - 320px) / (1920 - 320))) calc(12px + (15 - 12) * ((100vw - 320px) / (1920 - 320)));
        border-radius: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
        box-shadow: 15px 30px 50px rgba(23, 32, 90, 0.06);
    }
}

.header-section {
    display: flex;
    align-items: center;
    .nav {
        .nav-link {
            font-size: 16px;
            color: $title-color;
            text-transform: capitalize;
            font-weight: 600;
            letter-spacing: 0.02em;
        }
    }
}

.dark-light-btn {
    margin-right: 22px;
    @media (max-width: 576px) {
        margin-right: 0;
    }
    i {
        color: #737373;
        font-size: 18px;
    }
    .dark-btn {
        display: inline-block;
    }
    .light-btn {
        display: none;
    }
    &.light {
        .dark-btn {
            display: none;
        }
        .light-btn {
            display: inline-block;
        }
    }
}