/*=====================
    about us CSS start
==========================*/

.about-detail {
    p {
        font-size: 17px;
        color: $content-color;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
