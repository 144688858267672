/*=====================
    form CSS start
==========================*/
.form-control {
    border: 1px solid #eeeeee;
    border-radius: 8px;
}

.floating-height {
    height: 46px;
    line-height: 32px;
}

.file-input {
    display: inline-block;
    text-align: left;
    background: $white;
    padding: 0;
    position: relative;
    border-radius: 3px;
    width: 100%;
    padding: 10px;
    border: 1px dashed #ddd;
    border-radius: 8px;
    background-color: $white;
    @media (max-width: 380px) {
      padding: 10px;
    }
    > [type='file'] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 10;
        cursor: pointer;
      }
      > .button {
        display: inline-block;
        cursor: pointer;
        background: #eee;
        padding: 8px 16px;
        border-radius: 2px;
        margin-right: 8px;
        @media (max-width: 380px) {
          margin-right: 2px;
          padding: 6px 10px;
        }
      }
      > .label {
        color: $content-color;
        white-space: nowrap;
        opacity: 1;
      }
      .-chosen > .label {
        opacity: 1;
      }
    
  }
