/*=====================
    Typography CSS start
==========================*/

body {
  font-family: 'Montserrat', sans-serif;
  position: relative;
  font-size: 14px;
  margin: 0;
  background-color: $white;
  color: $title-color;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

li {
  display: inline-block;
  font-size: 14px;
}

a {
  transition: 0.5s ease;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    transition: 0.5s ease;
  }

  &:focus {
    outline: none;
  }
}

:focus {
  outline: none;
}

.form-control {
  font-size: 14px;

  &:focus {
    box-shadow: none;
  }
}

h1 {
  font-size: 58px;
  font-weight: 500;
  line-height: 1.1;
  margin: 0;
  text-transform: capitalize;
  color: $title-color;

  span {
    font-size: 58px;
  }
}

h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  margin: 0;
  color: $title-color;
}

h3 {
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  color: $title-color;
}

h4 {
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
  color: $title-color;
}

h5 {
  font-size: 17px;
  line-height: 1.3;
  margin: 0;
  color: $content-color;
}

h6 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
  color: $content-color;
}

span {
  font-size: 14px;
}

p {
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  line-height: 1.6;
  color: $content-color;
  margin-bottom: 0;
}

.title-color {
  color: $title-color;
}


.content-color {
  color: $content-color;
}