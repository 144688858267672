/*=====================
    Button CSS start
==========================*/

button:focus {
  outline: none;
}

.btn-theme {
  min-width: 180px;
  padding: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320))) calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
  color: $white;
  cursor: pointer;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  background-image: linear-gradient(to right, $theme-color2, $theme-color1, $theme-color1, $theme-color2);
  box-shadow: 0 5px 15px rgba(#2780d4, .4);
  transition: all .4s ease-in-out;
  text-transform: uppercase;
  border-radius: 10px;

  &:hover {
    background-position: 100% 0;
    transition: all .4s ease-in-out;
    color: $white;
  }

  &.btn-sm {
    text-transform: capitalize;
    padding: 10px 24px;
    width: auto;
  }

  &.btn-white {
    background-image: none;
    color: $theme-color1;
    background-color: $white;
    box-shadow: rgba(39, 128, 212, 0.10) 2px 1px 10px;
  }

  &.btn-light {
    background-color: #4c9be1;
    background-image: none;
    color: $white;
    transition: all .4s ease-in-out;
  }
}