/*=====================
    Reset CSS start
==========================*/

section,
.section-t-space {
  padding-top: calc(40px + (90 - 40) * ((100vw - 320px) / (1920 - 320)));
}

.section-b-space {
  padding-bottom: calc(40px + (90 - 40) * ((100vw - 320px) / (1920 - 320)));
}

.medium-t-space {
  padding-top: calc(25px + (70 - 25) * ((100vw - 320px) / (1920 - 320)));
}

.medium-b-space {
  padding-bottom: calc(25px + (70 - 25) * ((100vw - 320px) / (1920 - 320)));
}

.small-t-space {
  padding-top: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.small-b-space {
  padding-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.custom-padding {
  padding: 0 100px;
}

.sticky-top {
  position: sticky;
  top: 110px;
  z-index: 1;
}

.alert-theme {
  background: linear-gradient(27deg, #f0f8ff 0%, #deeafb 100%);
  border: none;
  margin-bottom: 0;
  strong {
    font-weight: 600;
  }
  .btn-close {
    background-size: 12px;
  }
}

@media (min-width: 1430px) {
  .container {
    max-width: 1400px;
  }
}
