/*=====================
    Home CSS start
==========================*/
.home-vector {
  position: relative;
  border-radius: 0;
  height: 100vh;
  // background-image: url(../images/background/gradient-banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  z-index: 1;
  padding-top: 0;
  display: flex;
  align-items: center;
  background-position: bottom;
  overflow: hidden;

  @media (max-width: 1199px) {
    height: 75vh;
  }

  @media (max-width: 991px) {
    height: auto;
    padding: 170px 0 90px;
    background-image: linear-gradient(to right, #0c4393, #2780d4);
  }

  @media (max-width: 767px) {
    padding: 140px 0 60px;
  }

  &:after {
    position: absolute;
    content: "";
    background-image: url(../images/background/gradient-banner.png);
    width: 100%;
    height: 100%;
    background-position: bottom;
    left: -71px;
    top: 10px;
    z-index: -1;
    transform: rotate(2deg) scale(1.15);

    @media (max-width: 1199px) {
      transform: rotate(-4deg) scale(2);
      left: 0;
    }

    @media (max-width: 991px) {
      display: none;
    }
  }

  .home-content {
    display: flex;
    align-items: center;
    height: 100%;

    h1 {
      color: $light-title;
      margin-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 700;
      font-size: calc(18px + (46 - 18) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.3;
      text-transform: unset;
      margin-top: -11px;

      span.effect {
        position: relative;
        z-index: 1;

        &:after {
          content: "";
          position: absolute;
          bottom: 7px;
          left: 0;
          z-index: -1;
          height: 17px;
          width: 100%;
          background-color: #fad8b0;
        }
      }
    }

    p {
      line-height: calc(26px + (30 - 26) * ((100vw - 320px) / (1920 - 320)));
      color: $light-content;
    }

    h3 {
      line-height: 28px;
      margin-bottom: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 400;
      letter-spacing: 0.02em;
      max-width: 100%;
      color: $light-content;

      @media (max-width: 1199px) {
        max-width: 100%;
      }
    }

    .line-span {
      display: block;
      width: 90px;
      height: 5px;
      background-color: $theme-color1;
      margin-bottom: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    .bottom-sec {
      display: flex;
      align-items: center;
      margin-top: 16px;

      a {
        + a {
          margin-left: 15px;
        }
      }

      .rating-part {
        margin-left: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 14px;

        .rating-detail {
          margin-left: 8px;

          li {
            svg {
              width: 18px;
              fill: #f8a512;
              stroke: #f8a512;
            }

            + li {
              margin-left: 4px;
            }
          }

          .clutch-img {
            width: 60px;
          }
        }
      }

      h2 {
        font-weight: 500;
        font-size: 29px;
        padding-top: 4px;
      }

      @media (max-width: 420px) {
        display: block;

        a {
          width: 100%;

          + a {
            margin-top: 10px;
            margin-left: 0;
          }
        }
      }
    }
  }

  .vector-section {
    position: relative;
    margin: 0 auto;
    width: 88%;

    .girl-svg {
      position: absolute;
      left: 27%;
      bottom: -2px;
      animation: float 6s ease-in-out infinite;

      @media (max-width: 1400px) {
        width: 45%;
      }
    }

    .tab-effect {
      width: 19%;
      position: absolute;
      right: 6%;
      top: 19%;
      animation: shimmy1 3s infinite;
      animation-direction: alternate;
    }

    .tab2-effect {
      position: absolute;
      left: 53%;
      width: 24%;
      top: 4%;
      animation: shimmy 3s infinite;
      animation-direction: alternate;
    }

    .upwork-box {
      animation: up-down linear 4s;
      animation-iteration-count: infinite;
      position: absolute;
      right: 0;
      top: 40px;
    }
  }

  .bottom-arrow {
    position: absolute;
    left: 50%;
    bottom: 10px;
    width: 60px;
    height: 60px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    box-shadow: 0px 2px 8px 0px #ddd;

    svg {
      stroke: $title-color;
    }
  }

  &.inner-home {
    background-image: none;
    padding-top: 220px;
    padding-bottom: 75px;
    background-image: linear-gradient(to right, #0c4393, #2780d4);
    height: auto;

    &.contact-page {
      padding-top: 220px;
      padding-bottom: 120px;

      @media (max-width: 1199px) {
        padding-top: 142px;
        padding-bottom: 100px;
      }
    }

    @media (max-width: 1199px) {
      padding-top: 170px;
      padding-bottom: 75px;
    }

    @media (max-width: 991px) {
      padding-top: 150px;
      padding-bottom: 55px;
    }

    @media (max-width: 767px) {
      padding-top: 128px;
      padding-bottom: 38px;
    }

    &::after {
      display: none;
    }
  }

  &.hire-detail-section {
    background-image: none;
    background-color: #fbf3ec;
    padding-top: 240px;
    background-color: rgba(255, 255, 255, 0.83);
    background-image: url(https://image.freepik.com/free-vector/vibrant-summer-ombre-background-vector_53876-105765.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: overlay;
    padding-bottom: 100px;
    height: auto;

    @media (max-width: 1199px) {
      padding-top: 200px;
      padding-bottom: 100px;
    }

    @media (max-width: 991px) {
      padding-top: 140px;
      padding-bottom: 40px;
    }
  }
}

.breadcrumb-section {
  text-align: center;

  h2 {
    margin-top: -9px;
    color: $light-title;
    font-size: calc(26px + (40 - 26) * ((100vw - 320px) / (1920 - 320)));
  }

  h3 {
    font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320)));
    line-height: 28px;
    color: $light-title;
  }
 
  .opening-details {
    display: flex;
    align-items: center;
    margin: calc(0px + (-2 - 0) * ((100vw - 320px) / (1920 - 320))) -15px;
    flex-wrap: wrap;
    overflow: hidden;

    @media (max-width: 767px) {
      margin-top: 6px;
    }

    h6 {
      position: relative;
      text-transform: capitalize;
      color: rgba($light-content, 0.8);
      margin: calc(0px + (2 - 0) * ((100vw - 320px) / (1920 - 320))) 15px;

      @media (max-width: 767px) {
        font-size: 14px;
      }

      &:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 10px;
        background-color: #c9c9c9;
        left: -16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .breadcrumb-item {
    &.active {
      color: $light-content;
    }

    + .breadcrumb-item {
      &::before {
        color: $light-content;
      }
    }
  }

  .breadcrumb {
    justify-content: center;
    margin-bottom: 0;
    margin-top: 6px;

    .breadcrumb-item {
      a {
        color: $light-title;
      }
    }
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}
