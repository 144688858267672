/*=====================
    Responsive CSS start
==========================*/

@media (max-width: 1199px) {
    .single-tech-section {
        .single-tech-listing {
            .row {
                >div {

                    &:nth-child(5n + 5) {
                        border-right: none;
                    }

                    &:nth-child(6) {
                        border-right: 1px solid $light-content !important;
                    }

                    &:nth-last-child(2),
                    &:nth-last-child(3) {
                        border-bottom: 1px solid $light-content !important;
                    }

                    &:last-child {
                        border-left: 1px solid $light-content;
                        border-top: 1px solid $light-content;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .single-tech-section {
        .single-tech-listing {
            .row {
                >div {
                    &:nth-child(4n + 4){
                        border-right: 0;
                    }
                    &:nth-child(5n + 5){
                        border-right: 1px solid #ededed;
                    }
                    &:last-child {
                        border-top: none;
                        border-left: none;
                    }
                    &:nth-last-child(-n + 4) {
                        border-bottom: none !important;
                    }
                    &:nth-child(4) {
                        border-left: none;
                    }
                }
            }
        }
    }
}


@media (max-width: 576px) {
    .single-tech-section {
        .single-tech-listing {
            .row {
                >div {
                    &:nth-child(4n + 4){
                        border-right: 1px solid #ededed;
                    }
                    &:nth-child(3n + 3) {
                        border-right: none !important;
                    }
                    &:nth-last-child(-n + 4) {
                        border-bottom: 1px solid #ededed !important;
                    }
                    &:last-child {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .col-xs-6 {
        width: 100%;
    }
}

@media (max-width: 420px) {
    .single-tech-section {
        .single-tech-listing {
            .row {
                > div {
                    &:nth-child(1n + 2) {
                        border-right: 1px solid #ededed !important;
                    }
                    &:nth-child(2n + 2) {
                        border-right: none !important;
                    }
                    &:last-child {
                        border-left: none !important;
                    }
                    &:nth-last-child(2) {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }
}